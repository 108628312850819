import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FormControl from '@material-ui/core/FormControl'
import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import useUserActions from 'user-module/user/core/useUserActions'
import messageActions from 'shared-module/message/messageActions'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import useUser from '../../../core/useUser'

const ConsentFormButton = ({ checked, onClose, checkedEmail }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useUser()
  const form = useFormData()
  const userActions = useUserActions()

  const buildApiUser = data => ({
    firstName: data.firstName,
    lastName: data.lastName,
    consent: checked,
    marketingEmail: checkedEmail,
  })
  const register = (data) => {
    const userData = buildApiUser(data)
    userActions.update({ id: user.id, ...userData })
      .then(() => {
        onClose()
      })
      .catch(() => {
        dispatch(messageActions.showError())
      })
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <VoskerPrimaryButton
        id="consent_button"
        color="primary"
        variety="primary"
        type="submit"
        onClick={form.handleSubmit(register)}
        disabled={form.formState.isSubmitting || !form.formState.isValid || !checked}
      >
        { t('button.continue') }
      </VoskerPrimaryButton>
    </FormControl>
  )
}

export default ConsentFormButton
