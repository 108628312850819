import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const DialogFullHdVideoPendingRequest = ({ open, onClose }) => {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      open={open}
      title={t('photo_viewer.full_hd_video.pending_button')}
      text={t('photo_viewer.full_hd_video.pending')}
      cancelText={t('button.dismiss')}
      onCancel={onClose}
      onClose={onClose}
    />
  )
}

export default DialogFullHdVideoPendingRequest
