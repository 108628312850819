import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'

import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import NoPlan from './current-subscription/NoPlan'
import CurrentPlan from './current-subscription/CurrentPlan'
import UpcomingPlan from './current-subscription/UpcomingPlan'
import AutoRenew from './current-subscription/AutoRenew'
import PaymentStatus from './current-subscription/PaymentStatus'
import Warning from './current-subscription/Warning'
import LinearProgress from '@material-ui/core/LinearProgress'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import AutoRenewalManagement from 'vosker/src/camera-module/transmission-plan/ui/header/current-subscription/AutoRenewalManagement'
import InfinityIcon from 'camera-module/cameras/ui/cameras-page/icons/InfinityIcon'

const useStyles = makeStyles((theme) => ({
  dataUsage: {
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
    color: theme.palette.text.primary,
    marginBottom: 16,
  },
  progressBar: {
    marginBottom: 8,
    height: 8,
    backgroundColor: theme.palette.secondary.light,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.status?.info,
    },
  },
  pourcentage: {
    borderRadius: 4,
    width: 46,
    height: 20,
    padding: '4px 8px',
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette?.border?.primary,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  infinity: {
    borderRadius: 4,
    fontSize: 32,
    width: 32,
    height: 20,
    padding: '4px 8px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette?.border?.primary,
    '& svg': {
      fontSize: 32,
    },
  },
  sectionContainer: {
    marginBottom: 13.5,
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1em',
    color: theme.palette.text.primary,
    '&> li': {
      padding: '.3rem 0',
    },
  },
}))

const CurrentSubscription = ({ eCommerceIsDown = false }) => {
  const classes = useStyles()
  const camera = useSelectedCamera()
  const { t } = useTranslation()

  const subscription = camera?.subscription
  const planId = subscription?.plan?.id
  const hasPlan = planId && planId !== apiCameraPlans.empty
  const count = camera?.subscription?.photoCount || 0
  const limit = camera?.subscription?.photoLimit || 0
  const usage = limit > 0 ? (count / limit) * 100 : 0

  const isElitePlan =
  subscription?.plan?.name === apiCameraPlans.elite && !subscription?.isFree

  const isEliteFreeTrial =
  subscription?.plan?.name === apiCameraPlans.elite &&
  subscription?.isFree &&
  !camera?.isCompletingTrial

  if (!hasPlan) {
    return <NoPlan />
  }

  return (
    <Box>
      <Typography className={classes.dataUsage}>
        { t('app:plan.current_subscription.data_usage') }
      </Typography>
      <LinearProgress
        className={classes.progressBar}
        variant="determinate"
        value={isElitePlan || isEliteFreeTrial ? 100 : usage}
      />
      <Grid container className={classes.sectionContainer}>
        <Grid item xs={6} md={6}>
          { isElitePlan || isEliteFreeTrial
            ? (
              <Box className={classes.infinity}>
                <InfinityIcon />
              </Box>
              )
            : (
              <Box className={classes.pourcentage}>{ Math.floor(usage) }%</Box>
              ) }
        </Grid>
        <Grid item xs={6} md={6}>
          <Box style={{ textAlign: 'end' }}>
            { count } / { isElitePlan || isEliteFreeTrial ? t('billing.unlimited_photos') : `${limit} ${t('billing.photos')}` }
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <CurrentPlan />
      <UpcomingPlan />
      <AutoRenewalManagement />
      <AutoRenew />
      <PaymentStatus />

      <Warning />
    </Box>
  )
}

export default CurrentSubscription
