import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import VoskerOutlinedButton from 'vosker/src/components/buttons/VoskerOutlinedButton'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '1rem',
  },
  managePlan: {
    width: '100%',
    height: '53px',
    background: theme.palette.background.tile,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonLabel: {
    fontSize: '0.875em',
  },
}))

const ManagePlanButton = ({ camera }) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const handleClick = e => {
    e.stopPropagation()
    history.push(`/camera/${camera.id}/plan`)
  }

  return (
    <Grid item xs className={classes.container}>
      <VoskerOutlinedButton id={camera.id} onClick={handleClick}>
        { t('camera.managePlan') }
      </VoskerOutlinedButton>
    </Grid>
  )
}

export default ManagePlanButton
