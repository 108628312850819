import mixpanel from 'mixpanel-browser'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'

// DEV PROJECT TOKEN FOR TEST ANALYSIS
const MIXPANEL_DEV_TOKEN = 'ebe4f32cb0adecffbfc0d709a839b21f'
const MIXPANEL_PROD_TOKEN = '0c8aecd371dcfd7946928c6cf6e7b533'

const isDevelopment = window.location.origin.includes('dev') ||
  window.location.origin.includes('staging') ||
  window.location.origin.includes('localhost')

mixpanel.init(isDevelopment ? MIXPANEL_DEV_TOKEN : MIXPANEL_PROD_TOKEN)

const actions = {
  identify: id => mixpanel.identify(id),
  track: (name, props) => mixpanel.track(name, props),
  people: { set: props => mixpanel.people.set(props) },
}

const getHighestSubscription = cameras => {
  const subscriptions = []
  cameras.forEach(camera => camera.subscription && subscriptions.push(camera.subscription.plan.name))

  if (subscriptions.includes(apiCameraPlans.elite)) return apiCameraPlans.elite
  else if (subscriptions.includes(apiCameraPlans.alerted)) return apiCameraPlans.alerted
  else if (subscriptions.includes(apiCameraPlans.basic)) return apiCameraPlans.basic
  else return apiCameraPlans.free
}

export const defaultTrackingProperties = (user, cameras) => {
  return {
    'Created at': user.creationDate,
    'Updated at': user.lastProfileUpdateDate,
    Language: user.language,
    Environment: process.env.NODE_ENV,
    Newsletter: user.marketingEmail,
    'Cameras Owned': cameras?.length,
    'Highest Plan': cameras?.length === 0
      ? 'N/a'
      : getHighestSubscription(cameras),
  }
}

export const Mixpanel = actions
