import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Box, Card, CardHeader, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core'
import MoreIcon from 'camera-module/cameras/ui/cameras-page/icons/MoreIcon'
import HomeStatusIcon from 'vosker/src/assets/icons/HomeStatusIcon'
import SideSettingsIcon from 'vosker/src/assets/icons/SideSettingsIcon'
import HomeEventIcon from 'vosker/src/assets/icons/HomeEventIcon'
import HomePlanIcon from 'vosker/src/assets/icons/HomePlanIcon'
import BatteryIcon from 'camera-module/camera/core/BatteryIcon'
import CellularSignalIcon from 'camera-module/camera/core/CellularSignalIcon'
import CameraPhotoGallery from 'camera-module/cameras/ui/cameras-home/components/CameraPhotoGallery'
import { getHoursDiffBetweenDates } from 'shared-module/common-utils'
import CameraNotificationMessage from '../../cameras-page/camera-notification-message/CameraNotificationMessage'
import CameraUpdateMessage from '../../cameras-page/camera-updating-message/CameraUpdateMessage'
import CameraExpirationNotice from '../../cameras-page/camera-expiration-notice/CameraExpirationNotice'
import { useCriticalNotification } from 'notifications-module/core/notifications.hooks'
import { defaultTrackingProperties, Mixpanel } from 'vosker/src/Mixpanel'
import useUser from 'user-module/user/core/useUser'
import { useLoadCameras } from 'camera-module/cameras/core/useCameras'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    width: '100%',
    maxWidth: 252,
    minHeight: 140,
    margin: '10px',
    borderRadius: 6,
    overflow: 'visible',
    position: 'relative',
    boxShadow: 'none',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: 310,
    },
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    margin: '-18px',
    gap: theme.spacing(1.1),
  },
  media: {
    height: 134,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 6,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 163,
    },
  },
  navigation: {
    position: 'absolute',
    top: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    transform: 'translateY(-50%)',
  },
  cameraTitle: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '16.77px',
    marginBottom: '-11px',
    margin: '-12px -15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  menuButton: {
    backgroundColor: 'transparent',
    height: '24px',
    borderRadius: '6px',
    padding: '0',
    left: '-10px',
    maringRight: '10px',
    maringLeft: '5px',
    overflow: 'hidden',
    position: 'relative',
    display: 'inline-block',
    '&:hover, &.menu-open': {
      opacity: 1,
      backgroundColor: theme.palette.background.light,
    },
    '&:hover::after, &.menu-open::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: theme.palette.background.light,
      zIndex: -1,
    },
  },
  customMenu: {
    '& .MuiPaper-root': {
      borderRadius: '6px  6px 6px',
      padding: '6px 0',
      width: '128px',
      height: '133px',
      [theme.breakpoints.down('xs')]: {
        width: '120px',
        height: '130px',
        padding: '2px 0',
        marginLeft: '8px',
      },
    },
    '& .MuiMenu-list': {
      gap: '2px',
      padding: '0 8px 20px 0px',
      height: '120px',
      '&:focus': {
        backgroundColor: theme.palette.background.dropdown,
        width: '166px',
        gap: '2px',
      },
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Exo 2',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '9px',
      padding: '2px 15px',
      width: '150px',
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      '&:focus': {
        backgroundColor: theme.palette.border?.primary,
        color: 'transparent',
      },
      '&:hover': {
        width: '140px',
        backgroundColor: theme.palette.background?.empty,
      },
      '& .MuiBox-root': {
        color: theme.palette.text.primary,
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '30px',
        padding: '2px 15px',
        '&:focus': {
          backgroundColor: theme.palette.border?.primary,
          color: 'transparent',
        },
        '&:hover': {
          width: '139px',
          backgroundColor: theme.palette.background.empty,
        },
      },
    },
  },
  divider: {
    borderTop: `solid 1px ${theme.palette.secondary.main}`,
    top: '3px',
    width: '127px',
  },
}))

const CameraHomeTile = ({ camera, photos }) => {
  const user = useUser()
  const cameras = useLoadCameras()
  const getTruncatedName = (name) => {
    if (!name) return ''

    const MAX_LENGTH = 24
    const MAX_UPPERCASE_LENGTH = 12
    const TRUNCATION_INDICATOR = '...'

    const truncate = (str, length) =>
      str.length > length ? `${str.substring(0, length).trim()}${TRUNCATION_INDICATOR}` : str

    const nameIsUppercase = isUppercase(name)

    return {
      truncatedName: nameIsUppercase ? truncate(name, MAX_UPPERCASE_LENGTH) : truncate(name, MAX_LENGTH),
      isUppercase: nameIsUppercase,
    }
  }

  const isUppercase = (str) => str === str.toUpperCase()
  const { truncatedName, isUppercase: isNameUppercase } = getTruncatedName(camera?.config?.name)

  const classes = useStyles({ maxLength: truncatedName?.length, isUppercase: isNameUppercase })
  const history = useHistory()
  const { t } = useTranslation()
  const [menuAnchor, setMenuAnchor] = useState(null)

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchor(null)
  }

  const handleRedirect = (path) => {
    setMenuAnchor(null)
    history.push(path)
  }

  const handleRedirectionWithTracking = async (path, option) => {
    handleRedirect(path)

    await Mixpanel.track('Context Menu Opened', {
      'Camera Name': camera.config.name,
      'Selected Option': option,
      ...defaultTrackingProperties(user, cameras),
    })
  }

  const hasPlan = camera?.subscription?.plan?.id !== 'Empty'
  const endDate = camera?.subscription?.endDateBillingCycle
  const hoursLeftBeforeExpiration = getHoursDiffBetweenDates(Date.now(), endDate)
  const isPlanExpired = !hasPlan || hoursLeftBeforeExpiration <= 0

  const criticalNotification = useCriticalNotification(camera.id)
  const renderCameraTileMessages = () => {
    return (
      <>
        { criticalNotification && <CameraNotificationMessage notification={criticalNotification} /> }
        { camera?.status?.updateInProgress && <CameraUpdateMessage style={{ marginLeft: '-0.85rem' }} /> }
        <CameraExpirationNotice camera={camera} />
      </>
    )
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title={truncatedName}
        classes={{
          title: classes.cameraTitle,
        }}
        action={(
          <div className={classes.icons}>
            <CellularSignalIcon bar={camera?.status?.signal?.bar} viewBox="-17 0 52 42" />
            <Box mt={1} ml={-0.8} mr={0.5}>
              <BatteryIcon percentage={camera?.status?.mainBattery?.percentage} viewBox="-10 0 52 42" />
            </Box>

            <IconButton
              onClick={handleMenuOpen}
              className={`${classes.menuButton} ${menuAnchor ? 'menu-open' : ''}`}
              data-testid="menu-button"
            >
              <MoreIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              keepMounted
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
              anchorReference="anchorPosition"
              anchorPosition={
                menuAnchor
                  ? { top: menuAnchor.getBoundingClientRect().bottom + 6, left: menuAnchor.getBoundingClientRect().left - 102 }
                  : undefined
              }
              className={classes.customMenu}
              MenuListProps={{
                autoFocusItem: false,
              }}
            >
              <MenuItem onClick={() => handleRedirectionWithTracking(`/camera/${camera.id}/status`, 'STATUS')}>
                <Box display="inline-flex" alignItems="center" mr={0.8} mt={0.3}><HomeStatusIcon viewBox="0 0 19 18" /></Box>
                <Box mb={0.2}>{ t('app:menu.status') }</Box>
              </MenuItem>
              <MenuItem onClick={() => handleRedirectionWithTracking(`/camera/${camera.id}/settings`, 'SETTINGS')}>
                <Box display="inline-flex" alignItems="center" mr={0.8}><SideSettingsIcon width="16" height="16" viewBox="0 0 27 25" /></Box>
                <Box mb={0.2}>{ t('app:menu.settings') }</Box>
              </MenuItem>
              <MenuItem onClick={() => handleRedirectionWithTracking(`/camera/${camera.id}/plan`, 'PLAN')}>
                <Box display="inline-flex" alignItems="center" mr={0.8}><HomePlanIcon viewBox="0 0 19 17" /></Box>
                <Box mb={0.2}>{ t('app:menu.plan') }</Box>
              </MenuItem>
              <MenuItem onClick={() => handleRedirectionWithTracking(`/camera/${camera.id}`, 'GO TO GALLERY')} className={classes.divider}>
                <Box display="inline-flex" alignItems="center" mr={0.9} mt={0.5}><HomeEventIcon viewBox="1 0 17 17" /></Box>
                <Box mt={0.2}>{ t('app:menu.event') }</Box>
              </MenuItem>
            </Menu>
          </div>
        )}
      />
      <Box className={classes.media}>
        <CameraPhotoGallery
          photos={photos}
          camera={camera}
          cameraId={camera.id}
          handleRedirect={handleRedirect}
          hasPlan={hasPlan}
          isPlanExpired={isPlanExpired}
        />
      </Box>
      <Box>
        { renderCameraTileMessages() }
      </Box>
    </Card>
  )
}

export default CameraHomeTile
