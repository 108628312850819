import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import Form from 'form-module/ui/Form'
import Input from 'form-module/ui/inputs/Input'
import SdCardWarning from 'camera-module/camera/ui/add-camera-form/sd-card-warning/SdCardWarning'
import CameraActivationCard from 'form-module/ui/fields/camera-activation/CameraActivationCard'
import cameraActivationValidationSchema from 'vosker/src/camera-module/camera/core/cameraActivationValidationSchema'
import CameraSubmission from 'form-module/ui/fields/camera-activation/CameraSubmission'
import CameraModelSelector from 'form-module/ui/fields/camera-activation/CameraModelSelector'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'

const Section = ({ children, ...props }) => <Grid container spacing={2} {...props}>{ children }</Grid>
const FieldSubSection = ({ children, ...props }) => <Grid item sm={7} {...props}>{ children }</Grid>
const HelpSubSection = ({ children, ...props }) => <Grid item sm={5} {...props}>{ children }</Grid>
const Row = ({ children, ...props }) => <Grid container spacing={2} style={{ marginBottom: '4px' }} {...props}>{ children }</Grid>
const Cell = ({ children, ...props }) => <Grid item xs={12} {...props}>{ children }</Grid>
const FullWidthCell = ({ children, ...props }) => <Grid item xs={12} {...props}>{ children }</Grid>

const VoskerCameraActivationForm = ({ onSubmit, onClose }) => {
  const { t } = useTranslation()

  const initialValues = {
    deviceModel: '',
    activationCode: '',
    deviceName: '',
  }
  return (
    <Form
      data={initialValues}
      onSubmit={onSubmit}
      style={{ paddingBottom: 20 }}
      validationSchema={cameraActivationValidationSchema()}
    >
      <Section>
        <FieldSubSection>

          <Row>
            <Cell>
              <CameraModelSelector />
            </Cell>
          </Row>

          <Row>
            <Cell>
              <Input required name="activationCode" label={t('app:active_device.activationId')} maxLength={20} />
            </Cell>
          </Row>

          <Row>
            <Cell>
              <Input required name="deviceName" label={t('add_camera.name')} maxLength={30} />
            </Cell>
          </Row>

          <Row style={{ paddingBottom: '12px' }}>
            <FullWidthCell align="left">
              <Grid container spacing={1}>
                <Grid item sm={3}><VoskerSecondaryButton id="cancelButton" onClick={onClose}>{ t('button.cancel') }</VoskerSecondaryButton></Grid>
                <Grid item sm={3}><CameraSubmission /></Grid>
              </Grid>
            </FullWidthCell>
          </Row>

          <Row>
            <Cell>
              <SdCardWarning />
            </Cell>
          </Row>

        </FieldSubSection>

        <HelpSubSection>
          <CameraActivationCard />
        </HelpSubSection>

      </Section>
    </Form>
  )
}

export default VoskerCameraActivationForm
