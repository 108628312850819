import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import CameraSettingsSection from './CameraSettingsSection'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import Spinner from 'shared-module/components/Spinner.js'
import { cameraSettingsAlertContents } from './helpers/cameraSettings.helper'
import cameraSettingsSchema from './cameraSettings.schema'
import getInputs from './helpers/settingInputFactory'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useCameraSettings } from 'camera-module/camera/core/cameraSettings.hooks'
import { useDidMountEffect } from '../../core/cameraSettings.hooks'
import { useFormikContext } from 'formik'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import { canSubmitForm, isFormTouched } from 'form-module/ui/util'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
    },
  },
  saveButtonContainer: {
    justifyContent: 'end',
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: '0 0 24px 0',
    },
  },
  formButtons: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 8,
    },
  },
}))

const CameraSettingsFormContent = ({ setValidationSchema, onReset, dialogActions }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const importedClasses = VoskerInputStyles()
  const camera = useSelectedCamera()
  const form = useFormikContext()
  const settings = useCameraSettings(camera, form)
  const [alertContent, setAlertContent] = useState()

  const detectionZoneEnabled = appConfig.detectionZone.switch === switches.on
  const isMobileResolution = useMediaQuery(theme.breakpoints.only('xs'))
  const getSpacingByResolution = isMobileResolution ? 0 : 2

  const close = () => setAlertContent(undefined)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputs = useMemo(() => getInputs({ settings, t, importedClasses, classes, dialogActions }), [settings, t, importedClasses, dialogActions])

  useDidMountEffect(() => {
    if (form.values.batteryType === '12V') setAlertContent(cameraSettingsAlertContents.batteryType)
  }, [form.values.batteryType])

  useEffect(() => {
    if (settings) {
      setValidationSchema(cameraSettingsSchema(settings))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings])

  useEffect(() => {
    form.setValues(camera.config)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera.config])

  useEffect(() => {
    if (detectionZoneEnabled && camera.config.detectionZone) {
      form.setValues({ ...form.values, detectionZoneNotificationEnabled: camera.config.detectionZone.isEnabled })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera])

  if (!settings || !camera) {
    return <Spinner />
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box display="flex" justifyContent="center" width={1} maxWidth={800}>
        <Box width={1}>
          { Object.keys(settings).map((sectionKey) => (
            <CameraSettingsSection key={sectionKey} sectionKey={sectionKey}>
              { settings[sectionKey].map((groupSetting) => {
                if (!inputs[groupSetting.name]) {
                  return null
                }
                return (
                  <Box key={groupSetting.name} py={1.5}>
                    { inputs[groupSetting.name] }
                  </Box>
                )
              }) }
            </CameraSettingsSection>
          )) }
          <Box display="flex" className={classes.saveButtonContainer}>
            <Grid spacing={getSpacingByResolution} container justifyContent="flex-end" xs={12}>
              <Grid item sm="auto" xs={12}>
                <VoskerSecondaryButton color="primary" variant="outlined" onClick={onReset} className={classes.formButtons}>
                  { t('camera.settings.buttons.resetSettings') }
                </VoskerSecondaryButton>
              </Grid>
              <Grid item sm="auto" xs={12}>
                <VoskerPrimaryButton type="submit" className={classes.formButtons} disabled={!isFormTouched(form) || !canSubmitForm(form)}>
                  { t('camera.settings.buttons.save') }
                </VoskerPrimaryButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <ConfirmationDialog
        open={!!alertContent}
        title={alertContent?.title}
        text={alertContent?.text}
        onConfirm={close}
        confirmText={alertContent?.confirmText}
      />
    </Box>
  )
}

export default CameraSettingsFormContent
