import { useSelector, useDispatch } from 'react-redux'
import cameraPurposesActions from './cameraPurposesActions'
import cameraPurposesState from './cameraPurposesState'

const useCameraPurposes = () => {
  const state = useSelector((state) => state.purposes)
  const dispatch = useDispatch()

  if (!cameraPurposesState.isReady(state)) {
    dispatch(cameraPurposesActions.getPurposes())
  }

  return state?.purposes
}

export default useCameraPurposes
