import { v4 as uuid } from 'uuid'
import apm from 'shared-module/monitoring/apm'
import userApi from 'user-module/user/api/userApi'
import jwtToken from 'shared-module/token/jwtToken.js'
import appResetState from 'shared-module/app-reset/appResetState'
import messageActions from 'shared-module/message/messageActions'

import { FETCH_USER, SET_USER } from 'user-module/user/core/userActionTypes'

const set = user => ({ type: SET_USER, user })

const getAndSet = userId => async dispatch => {
  const user = await userApi.getById(userId)

  apm.setUserContext({ id: user.id, email: user.email })
  return dispatch(set(user))
}

const get = userId => dispatch => {
  dispatch(({ type: FETCH_USER }))

  return dispatch(getAndSet(userId))
}

const update = user => dispatch => userApi
  .update(user)
  .then(() => dispatch(getAndSet(user.id)))

// Utility method
const decodeUserId = () => {
  try {
    return jwtToken.decodeUserId()
  } catch {}
}

const reload = userId => dispatch =>
  userApi
    .getById(userId)
    .then(user => dispatch(set(user)))

const login = (token, refresh) => dispatch => {
  jwtToken.set(token)
  jwtToken.setRefresh(refresh)

  const userId = decodeUserId()
  return dispatch(get(userId))
}

const register = user => dispatch => {
  const deviceID = jwtToken.getDevice() !== null ? jwtToken.getDevice() : uuid()
  jwtToken.setDevice(deviceID)

  return userApi.register({ ...user, deviceID })
    .then(response => dispatch(login(response.data.token, response.data.refresh)))
}

const loginUser = (username, password) => dispatch => {
  apm.addLabels({ source: 'webapp' })
  const deviceID = jwtToken.getDevice() !== null ? jwtToken.getDevice() : uuid()
  jwtToken.setDevice(deviceID)

  return userApi.login({ username, password, deviceID })
    .then(response => dispatch(login(response.data.token, response.data.refresh)))
}

const logout = () => dispatch => {
  window.location = '/'
  jwtToken.remove()
  dispatch(appResetState.reset())
}

const deleteUser = () => dispatch =>
  userApi
    .deleteUser()
    .catch(() => dispatch(messageActions.showError('app:account.management.delete.message.error')))

const updatePassword = (password, newPassword) => (dispatch) =>
  userApi
    .updatePassword(password, newPassword)
    .then(() => dispatch(messageActions.showSuccess('app:account.profile.password.message.success')))
    .catch(() => dispatch(messageActions.showError('app:account.profile.password.message.error')))

const validatePassword = password => dispatch =>
  userApi
    .validatePassword(password)
    .then(response => {
      jwtToken.set(response.data.token)
      return dispatch(messageActions.showSuccess('app:account.management.password.message.success'))
    })
    .catch(() => dispatch(messageActions.showError('app:account.management.password.message.error')))

const userActions =
  {
    get: get,
    set: set,
    update: update,
    updateExtended: () => ({ type: 'NO_OP' }),
    reload: reload,
    register: register,
    login: login,
    loginUser: loginUser,
    logout: logout,
    deleteUser: deleteUser,
    updatePassword: updatePassword,
    validatePassword: validatePassword,
  }
export default userActions
