import { makeStyles } from '@material-ui/core/styles'
import PhotoGallery from 'photo-module/photos/ui/photos-gallery/PhotoGallery'
import DeletePhotosContainer from 'photo-module/photos/ui/photos-gallery/actions-buttons/DeletePhotosContainer'
import EventsOverlay from 'vosker/src/photos-module/photos-gallery/ui/events-overlay/EventsOverlay'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'wrap',
    width: '100%',
  },
  push: {
    textAlign: 'right',
    width: '100%',
    minHeight: 40,
    paddingRight: 10,
  },
})

const PhotoContainer = () => {
  const classes = useStyles()
  const camera = useSelectedCamera()
  const hasPlan = camera?.subscription?.plan?.id !== apiCameraPlans.Empty

  return (
    <div className={classes.root}>
      <div className={classes.push}>{ hasPlan && <DeletePhotosContainer /> }</div>
      <PhotoGallery PhotoOverlay={EventsOverlay} />
    </div>
  )
}

export default PhotoContainer
