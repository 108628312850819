import { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import VoskerMenu from 'vosker/src/shared-module/navigation/menu/VoskerMenu'
import Navbar from 'shared-module/navigation/Navbar'
import VoskerClickableLogo from 'vosker/src/shared-module/navigation/app-bar/VoskerClickableLogo'
import WebviewBackButton from 'vosker/src/shared-module/navigation/app-bar/WebviewBackButton'
import VoskerCartLink from 'vosker/src/camera-module/cart-link/VoskerCartLink'
import NotificationsOutlinedIcon from 'assets/icons/NotificationsOutlinedIcon'
import NotificationsOutlinedIconActive from 'assets/icons/NotificationsOutlinedIconActive'
import webview from 'shared-module/webview/webview'
import LegacyVoskerMenu from 'vosker/src/shared-module/navigation/menu/LegacyVoskerMenu'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'

const useStyles = makeStyles(theme => ({
  navbar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiButton-root:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}))

const VoskerAppBar = () => {
  const classes = useStyles()
  const homePageEnabled = useHomePageFlag()

  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const handleToggleMenu = () => setIsMenuVisible(visible => !visible)

  return !webview.isWebview() && (
    <div>
      <Navbar
        onClick={handleToggleMenu}
        Logo={VoskerClickableLogo}
        BackButton={WebviewBackButton}
        CartButton={VoskerCartLink}
        notificationIcon={NotificationsOutlinedIcon}
        notificationIconActive={NotificationsOutlinedIconActive}
        overrideClasses={classes}
      />

      { homePageEnabled
        ? <VoskerMenu open={isMenuVisible} onClose={handleToggleMenu} />
        : <LegacyVoskerMenu open={isMenuVisible} onClose={handleToggleMenu} /> }

    </div>
  )
}

export default VoskerAppBar
