import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Step from '@mui/material/Step'
import Stepper from '@mui/material/Stepper'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import StepContent from '@mui/material/StepContent'
import StepConnector from '@mui/material/StepConnector'
import { makeStyles } from '@material-ui/core/styles'

import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'

export interface ICVerticalSteps {
  steps: Array<any>
  expandable?: boolean
  enableReset?: boolean
  enableStepBack?: boolean
  isCompleted?: boolean
  validateCompletion?: boolean
  disabled?: boolean
  className?: any
}

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    // @ts-ignore
    borderColor: `${theme.palette.border?.primary || theme.palette.secondary.main} !important`,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1.5,
    '&.MuiStepContent-root': {
      // @ts-ignore
      borderLeft: `1.5px solid ${theme.palette.border?.primary || theme.palette.secondary.main} !important`,
    },
    '& .MuiStepConnector-line': {
      border: 'none !important',
    },
  },
  stepIconRoot: {
    color: `${theme.palette.secondary.main} ! important`,
  },
  stepIconRootActive: {
    color: `${theme.palette.primary.main} ! important`,
  },
  stepIconRootCompleted: {
    color: `${theme.palette.primary.main} ! important`,
  },
  stepIconText: {
    fill: theme.palette.background.default + ' !important',
  },
  label: {
    '& .MuiStepLabel-label': {
      paddingLeft: 32,
      fontWeight: 'bold',
      fontSize: '14px !important',
      color: theme.palette.text.disabled + ' !important',
    },
    '& .MuiStepLabel-label.Mui-active': {
      fontWeight: 'bold',
      fontSize: '14px !important',
      color: theme.palette.text.primary + ' !important',
    },
    '& .MuiStepLabel-label.Mui-completed': {
      fontWeight: 'bold',
      fontSize: '14px !important',
      color: theme.palette.text.disabled + ' !important',
    },
  },
  step: {
    flex: 3,
  },
  disable: {
    color: theme.palette.text.disabled + ' !important',
  },
  description: {
    paddingLeft: 32,
    fontSize: '14px !important',
    whiteSpace: 'pre-line',
    color: theme.palette.text.primary,
  },
  next: {
    marginLeft: 32,
    marginTop: 20,
  },
  back: {
    marginLeft: 12,
    marginTop: 20,
  },
  reset: {
    marginLeft: 64,
    marginTop: 32,
    maxWidth: 'fit-content',
  },
}))

const CVerticalSteps = (props: ICVerticalSteps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [activeStep, setActiveStep] = useState(0)

  const handleReset = () => setActiveStep(() => 0)
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const isStepDisabled = activeStep === props.steps.length - 1 && props.validateCompletion && !props.isCompleted
  useEffect(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), [activeStep])

  return (
    <Box>
      <Stepper
        connector={(<StepConnector className={classes.contentRoot} />)}
        activeStep={props.disabled ? -1 : activeStep}
        orientation="vertical"
      >
        { props.steps.map((step, index) => (
          <Step key={step.label} expanded={props.expandable && !props.disabled ? index === props.steps.length - 1 && index === activeStep - 1 : true} className={classes.step}>
            <StepLabel
              className={props.className?.label || classes.label}
              StepIconProps={{
                classes: {
                  root: props.className?.stepIconRoot || classes.stepIconRoot,
                  active: props.className?.stepIconRootActive || classes.stepIconRootActive,
                  completed: props.className?.stepIconRootCompleted || classes.stepIconRootCompleted,
                  text: props.className?.stepIconText || classes.stepIconText,
                },
              }}
            >
              { t(step.label) }
            </StepLabel>

            <StepContent className={classes.contentRoot}>
              <Typography
                className={(index !== activeStep || props.disabled)
                  ? clsx(classes.disable, props.className?.description || classes.description)
                  : props.className?.description || classes.description}
              >
                { t(step.description) }
              </Typography>

              { step.renderAction && step.renderAction(index !== activeStep,
                handleNext,
                handleBack,
                handleReset) }

              <Grid container spacing={4}>
                { !step.removeStepButton && (
                  <Grid item>
                    <VoskerPrimaryButton
                      color="primary"
                      variety="primary"
                      onClick={handleNext}
                      className={classes.next}
                      disabled={props.disabled || index !== activeStep || isStepDisabled}
                    >
                      { t(step.actionLabel) }
                    </VoskerPrimaryButton>
                  </Grid>
                ) }

                { props.enableStepBack && activeStep !== 0 && index === activeStep && (
                  <Grid item>
                    <VoskerSecondaryButton color="primary" variety="primary" onClick={handleBack} className={classes.back}>
                      { t('button.back') }
                    </VoskerSecondaryButton>
                  </Grid>
                ) }
              </Grid>
            </StepContent>
          </Step>
        )) }

        { props.enableReset && activeStep === props.steps.length && (
          <VoskerPrimaryButton color="primary" variety="primary" onClick={handleReset} className={classes.reset}>
            { t('button.reset') }
          </VoskerPrimaryButton>
        ) }
      </Stepper>
    </Box>
  )
}

export default CVerticalSteps
