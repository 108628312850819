import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const DocumentIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="DocumentIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.17822 1.6665H14.0901L18.3158 5.8922V18.3332H3.17822V1.6665ZM4.55437 3.04265V16.957H16.9397V6.46222L13.5201 3.04265H4.55437Z"
        fill={theme.palette.status?.info}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.747 6.86528H6.9244V5.48913H10.747V6.86528Z"
        fill={theme.palette.status?.info}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5697 10.6879H6.9244V9.31177H14.5697V10.6879Z"
        fill={theme.palette.status?.info}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5697 14.5106H6.9244V13.1344H14.5697V14.5106Z"
        fill={theme.palette.status?.info}
      />
    </SvgIcon>
  )
}

export default DocumentIcon
