import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import FirstNameField from 'storybook-component-module/src/wrappers/fields/FirstNameField'
import clsx from 'clsx'
import LastNameField from 'storybook-component-module/src/wrappers/fields/LastNameField'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import TermsCheckbox from 'user-module/user/ui/register/components/TermsCheckbox.js'
import { useTranslation } from 'react-i18next'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import ConsentFormButton from 'user-module/user/ui/register/components/ConsentFormButton'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import Grid from '@material-ui/core/Grid'
import useUserActions from 'user-module/user/core/useUserActions'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import useUser from 'user-module/user/core/useUser'

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '500px',
    padding: '32px',
    margin: 'auto',
    borderRadius: '8px',
  },
  label: {
    marginTop: 10,
  },
  link: {
    color: `${theme.palette.status?.info}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: '0.25rem',
    },
  },
  titleStyle: {
    textAlign: 'left',
    fontFamily: 'exo2-semibold',
    padding: '0px',
    fontSize: '17px',
    fontWeight: 600,
    textTransform: undefined,
  },
  textContent: {
    textAlign: 'left',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: '16px',
    color: theme.palette.text.primary,
  },
  content: {
    overflowY: 'hidden !important',
  },
}))

const ConsentFormDialog = ({ onClose }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const importedClasses = VoskerInputStyles()
  const [checked, setChecked] = useState(false)
  const [checkedEmail] = useState(false)
  const userActions = useUserActions()
  const user = useUser()
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Dialog
      open
      onClose={onClose}
      disableBackdropClick
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle className={classes.titleStyle}> { t('consentForm.title') }</DialogTitle>
      <div style={{ alignSelf: 'left', textAlign: 'left' }}>
        <div className={classes.textContent} style={{ marginTop: '24px' }}>
          { t('consentForm.subtitle', { company: 'VOSKER®' }) }
        </div>
        <div className={classes.textContent}>
          { t('consentForm.content') }
        </div>
      </div>
      <FormDataContext>
        <FirstNameField
          fieldName="firstName"
          className={clsx(importedClasses.input, classes.input)}
          validations
          defaultValue={user?.firstName}
        />
        <LastNameField
          fieldName="lastName"
          className={clsx(importedClasses.input, classes.input)}
          validations
          defaultValue={user?.lastName}
        />
        <TermsCheckbox
          checked={checked}
          setChecked={setChecked}
          fieldName="consent"
          validations
        />
        { breakpoint
          ? (
            <Grid container justifyContent="flex-end">
              <Grid xs={12} item style={{ marginBottom: '8px' }}>
                <VoskerSecondaryButton color="primary" variety="secondary" onClick={() => userActions.logout()} style={{ width: '100%' }}>
                  { t('menu.logout') }
                </VoskerSecondaryButton>
              </Grid>
              <Grid xs={12} item>
                <ConsentFormButton checked={checked} checkedEmail={checkedEmail} onClose={onClose} />
              </Grid>
            </Grid>
            )
          : (
            <Grid container justifyContent="flex-end">
              <Grid item style={{ marginRight: '8px' }}>
                <VoskerSecondaryButton color="primary" variety="secondary" onClick={() => userActions.logout()} style={{ width: '100%' }}>
                  { t('menu.logout') }
                </VoskerSecondaryButton>
              </Grid>
              <Grid item>
                <ConsentFormButton checked={checked} onClose={onClose} />
              </Grid>
            </Grid>
            ) }
      </FormDataContext>
    </Dialog>
  )
}

export default ConsentFormDialog
