import { useTranslation } from 'react-i18next'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import Head from 'shared-module/new-components/head/Head'
import Title from 'shared-module/new-components/layout/Title'
import ResetPasswordForm from 'user-module/user/ui/reset-password/components/ResetPasswordForm'
import ResetPasswordSuccess from 'user-module/user/ui/reset-password/components/ResetPasswordSuccess'

const ResetPasswordPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <Head title={t('app:reset_password.head.title')} />
      <Title>{ t('reset_password.title') }</Title>
      <FormDataContext>
        <ResetPasswordForm />
        <ResetPasswordSuccess />
      </FormDataContext>
    </>
  )
}

export default ResetPasswordPage
