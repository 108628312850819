import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CircleAlert from '../../../../assets/icons/CircleAlert'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px',
    width: '10.25rem',
    height: '7.25rem',
    textAlign: 'center',
  },
  circleIcon: {
    fontSize: '1.1rem',
    marginRight: '5px',
  },
  value: ({ hasLowBattery }) => ({
    display: 'flex',
    margin: 'auto',
    fontSize: '0.813rem',
    fontFamily: theme.typography.fontFamilyBold,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: hasLowBattery ? theme.palette.status.error : hasLowBattery ? theme.palette.error.main : '',
  }),
  statusText: ({ hasLowBattery }) => ({
    display: '',
    marginTop: '10px',
    marginBottom: '0px',
    fontSize: '0.813rem',
    fontFamily: theme.typography.fontFamilyBold,
    textAlign: 'none',
    alignItems: 'none',
    justifyContent: 'none',
    color: hasLowBattery ? theme.palette.status.error : hasLowBattery ? theme.palette.error.main : '',
  }),
  label: {
    fontSize: theme.typography.caption2?.fontSize,
    color: theme.palette.text.primary,
  },
}))

const CameraStatusBatteryTile = ({ label, icon, value }) => {
  const { t } = useTranslation()
  const hasLowBattery = value >= 0 && value <= 34
  const classes = useStyles({ hasLowBattery })

  let statusKey = 'app:camera.battery_high'
  if (value <= 0) {
    statusKey = 'app:camera.battery_empty'
  } else if (value >= 1 && value <= 34) {
    statusKey = 'app:camera.battery_low'
  } else if (value >= 35 && value <= 74) {
    statusKey = 'app:camera.battery_medium'
  }

  return (
    <div className={classes.root} data-testid="tile">

      <Box pl={0.325} display="flex" justifyContent="center">
        <svg width="32" height="32" viewBox="0 0 32 32">{ icon }</svg>
      </Box>

      { hasLowBattery
        ? (
          <div className={classes.statusText}>
            { t(statusKey) }
          </div>
          )
        : (
          <div className={classes.statusText}>
            { hasLowBattery && <CircleAlert className={classes.circleIcon} /> } { t(statusKey) }
          </div>
          ) }

      <div className={classes.label}>{ t(label) }</div>
    </div>
  )
}

export default CameraStatusBatteryTile
