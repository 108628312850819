import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Route, useHistory, useLocation, useParams } from 'react-router-dom'
import Spinner from 'shared-module/components/Spinner'
import useCamera from 'camera-module/camera/core/useCamera'
import { PhotosProvider } from 'photo-module/photos/ui/photos-gallery/PhotoContext'
import PhotoViewer from 'photo-module/photo/ui/photo-viewer/PhotoViewer'
import { Dialog } from '@material-ui/core'
import { useEffect } from 'react'
import { parse } from 'qs'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '150px',
    [theme.breakpoints.only('sm')]: {
      marginTop: '140px',
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: '115px',
    },
  },
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '1.25rem',
    padding: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 0',
      padding: '1rem 0.5rem 1rem 1rem',
    },
  },
}))

const Dashboard = ({ TopBar, FiltersBar, PhotoViewerActionsBar, PhotoViewerContainer, PhotoContainer }) => {
  const classes = useStyles()
  const history = useHistory()
  const { id: cameraId } = useParams()
  const selectedCamera = useCamera(cameraId)

  const urlParams = useLocation().search.replace('?', '')
  const params = urlParams && parse(urlParams, { encode: false })

  useEffect(() => {
    params.error && history.push(`/camera/${cameraId}/plan?${urlParams}`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderFilterBar = () => {
    const isNoCellularCamera = !selectedCamera?.isCellular
    if (isNoCellularCamera) {
      return null
    }

    return <FiltersBar />
  }

  const renderPhotoContainer = () => (
    <Paper elevation="none" className={classes.paperContainer}>
      <PhotoContainer cameraId={cameraId} />
    </Paper>
  )

  const renderGallery = () => (
    selectedCamera
      ? (
        <div className={classes.root}>
          <PhotosProvider>
            <TopBar />
            { renderFilterBar() }
            { renderPhotoContainer() }
          </PhotosProvider>
        </div>
        )
      : <Spinner />
  )

  const onEscapeKeyDown = (_, reason) => {
    if (reason === 'escapeKeyDown') {
      history.push(`/camera/${cameraId}`)
    }
  }

  const renderViewer = () => (
    <Route
      path="/camera/:cameraId/photo/:photoId"
      render={() => {
        return (
          <Dialog
            onClose={onEscapeKeyDown}
            fullScreen
            PaperProps={{ style: { padding: 0, overflow: 'visible' } }}
            open={!!selectedCamera}
            PaperComponent="div"
          ><PhotoViewer ActionsBar={PhotoViewerActionsBar} PhotoViewerContainer={PhotoViewerContainer} />
          </Dialog>
        )
      }}
    />
  )

  return (
    <>
      { renderViewer() }
      { renderGallery() }
    </>
  )
}

export default Dashboard
