import Form from 'form-module/ui'
import { useHistory, useLocation } from 'react-router-dom'

import useUser from 'user-module/user/core/useUser'
import useUserActions from 'user-module/user/core/useUserActions'
import errorState from 'user-module/user/ui/profile/errorState'
import useMessageActions from 'shared-module/message/useMessageActions'
import MarketingConsentProvince from 'vosker/src/consent-module/MarketingConsentProvince'

const MarketingProvinceForm = ({ isOpenProvinceSelectionDialog, setIsOpenProvinceSelectionDialog }) => {
  const user = useUser()
  const history = useHistory()
  const location = useLocation()
  const userActions = useUserActions()
  const { showSuccess, showError } = useMessageActions()

  const userInitialValues = () => ({
    country: user.country,
    territory: '',
  })

  const buildUpdateUser = (values, userId) => ({ ...values, id: userId })

  const handleDialogClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setIsOpenProvinceSelectionDialog(false)
      history.replace({ ...location, state: { ...location.state, showProvinceDialog: false } })
    }
  }

  const onSubmit = values => {
    const profileValue = {
      territory: values.territory,
      cell: user?.cell || '',
    }
    const updateUser = buildUpdateUser(profileValue, user.id)

    return userActions
      .update(updateUser)
      .then(() => {
        showSuccess('profile.update')
      })
      .catch((error) => {
        if (!errorState.isHandled(error)) {
          showError('profile.error.text')
        }
      })
  }

  return isOpenProvinceSelectionDialog && (
    <Form id="mc-province-selection" onSubmit={onSubmit} data={{ ...userInitialValues() }}>
      <MarketingConsentProvince
        open={isOpenProvinceSelectionDialog}
        onClose={handleDialogClose}
      />
    </Form>
  )
}
export default MarketingProvinceForm
