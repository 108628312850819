import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'

const getPurposes = () => {
  return restapi
    .get('/api/v3/camera/purpose')
    .then((response) => response.data)
    .catch(handleError)
}

export default {
  getPurposes,
}
