import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import IOSSwitch from 'react-ios-switch'
import { useTheme } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'

import { getFieldError } from '../util'

const Switch = ({ name, label, labelPlacement, required = false, onChange, value, disabled = false }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isSubmitting, setFieldValue } = useFormikContext()
  const [field, meta, helpers] = useField(name)

  const error = getFieldError(meta, t)

  const onMuiSwitchChange = async (switchChangeEvent) => {
    if (onChange) {
      onChange(switchChangeEvent)
      if (switchChangeEvent.defaultPrevented) {
        return
      }
    }
    helpers.setTouched(true, false)
    setFieldValue(name, switchChangeEvent)
  }

  const SwitchControl = (
    <IOSSwitch
      disabled={disabled}
      checked={field.value}
      value={value ?? field.value}
      onChange={onMuiSwitchChange}
      offColor={theme.palette.secondary.light}
      onColor={theme.palette?.status?.info}
      style={{ transform: 'scale(0.8)', marginLeft: '0,375rem' }}
    />
  )

  return (
    <Grid display="flex" flexdirection="row" justifyContent="flex-end" container spacing={1}>
      <Grid style={{ marginRight: -15 }} item>
        <FormControl
          fullWidth
          required={required}
          error={!!error}
          disabled={field.disabled || isSubmitting}
        >
          <FormControlLabel
            label={label}
            labelPlacement={labelPlacement}
            control={SwitchControl}
          />
          { error && <FormHelperText>{ error }</FormHelperText> }
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Switch
