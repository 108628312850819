import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import AutoRenewSwitch from './AutoRenewSwitch'
import Divider from '@material-ui/core/Divider'
import { useECommerceFlag } from 'vosker/src/launchDarkly-module/useEcommerceFlag'

const AutoRenew = () => {
  const camera = useSelectedCamera()
  const eCommerceLdEnabled = useECommerceFlag()
  const subscription = camera?.subscription
  const plan = subscription?.plan

  const isCompletingTrial = camera.isCompletingTrial
  const onFreePlan = !isCompletingTrial && plan.isFree
  const onTrial = !isCompletingTrial && subscription.isFree && !plan.isFree
  const showAutoRenew = !onTrial && !onFreePlan

  return !eCommerceLdEnabled && showAutoRenew && (
    <AutoRenewSwitch />
  )
}

export default AutoRenew
