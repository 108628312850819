import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AddCameraIcon from 'camera-module/cameras/ui/cameras-page/icons/AddCameraIcon'
import CButtonIcon from 'storybook-component-module/src/components/buttons/CButtonIcon'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'

const useStyles = makeStyles((theme) => ({
  wrapper: ({ homePageEnabled }) => ({
    width: homePageEnabled && '253px !important',
    minWidth: homePageEnabled ? '255px' : 256,
    height: homePageEnabled ? 134 : 350,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: homePageEnabled ? '2.70rem 0.55rem' : '0.75rem 0.5rem',
    border: '1px solid' + theme.palette.secondary.main,
    borderRadius: '0.375rem',
    background: theme.palette.background.default,
    '&:hover': {
      cursor: 'pointer',
      opacity: 1,
      filter: 'brightness(102%)',
      background: theme.palette.background.default,
    },
    [theme.breakpoints.only('md')]: {
      minWidth: 224,
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: 218,
    },
  }),
  addButtonIcon: {
    padding: '0.5rem',
    marginBottom: '0rem',
    color: 'none',
    background: 'none',
    '&:hover': {
      color: 'none',
      background: 'none',
    },
  },
  icon: {
    width: 48,
    height: 48,
  },
}))

const ActivateCameraTile = ({ toggleAddCamera, displayText = true }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const homePageEnabled = useHomePageFlag()
  const classes = useStyles({ homePageEnabled })

  return (
    <Box container item xs sm={3} md={1} lg={2} xl={1} onClick={toggleAddCamera} className={classes.wrapper}>
      <CButtonIcon className={classes.addButtonIcon}>
        <AddCameraIcon className={classes.icon} color={theme.palette.secondary.main} />
      </CButtonIcon>

      { displayText && <CTypography variant="h3">{ t('app:active_device.activate_camera') }</CTypography> }
    </Box>
  )
}
export default ActivateCameraTile
