import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Form from 'form-module/ui'
import ActivateCameraIcon from 'vosker/src/assets/icons/ActivateCameraIcon'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import CameraActivationForm from 'vosker/src/camera-module/camera/ui/camera-activation/CameraActivationForm'
import { useMultiCameraActivationFlag } from 'vosker/src/launchDarkly-module/useMultiCameraActivationFlag'
import helper from 'vosker/src/camera-module/camera/ui/camera-activation/cameraActivationHelper'
import cameraApi from 'camera-module/camera/api/cameraApi'
import messageActions from 'shared-module/message/messageActions'

const useStyles = makeStyles(theme => ({
  appbar: {
    background: theme.palette.background.default,
    justifyContent: 'space-between',
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem !important',
      marginLeft: '-0.5rem',
      paddingTop: '0.2rem',
    },
  },
  icon: {
    width: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.1rem',
    },
  },
  container: {
    padding: '3rem 4.5rem 1rem 4.5rem',
    gap: '3rem',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0.375rem',
    height: 'fit-content',
  },
}))

const CameraActivation = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const multiCamActivation = useMultiCameraActivationFlag()
  const [allFieldsAreChanged, setAllFieldsAreChanged] = useState(false)

  const onSubmit = (data, { setFieldError }) => {
    const newCamera = {
      modelName: data?.deviceModel,
      ucid: data?.activationCode.replaceAll('-', ''),
      name: data?.deviceName,
      purpose: data?.purpose,
    }

    cameraApi
      .add(newCamera)
      .then(() => dispatch(messageActions.showSuccess(t('add_camera.camera_created'))))
      .catch(() => setFieldError('activationCode', t('app:active_device.form_layout.errors.activation_code')))
  }

  const exitActivation = () => history.goBack()

  return multiCamActivation && (
    <Box marginTop="150px">
      <CAppBar
        variant="h1"
        Icon={ActivateCameraIcon}
        title={t('app:active_device.page_layout.title')}
        className={classes.appbar}
        titleClassName={classes.title}
        iconClassName={classes.icon}
        showDivider
      />

      <Container maxWidth="md" className={classes.container}>
        <Form
          id="camera-activation"
          onSubmit={onSubmit}
          data={helper.getInitialValues()}
          validateOnChange={allFieldsAreChanged}
          validateOnBlur={false}
          validationSchema={helper.getValidationSchema(t)}
        >
          <CameraActivationForm
            allFieldsAreChanged={allFieldsAreChanged}
            setAllFieldsAreChanged={setAllFieldsAreChanged}
            exitActivation={exitActivation}
          />
        </Form>
      </Container>
    </Box>
  )
}

export default CameraActivation
