import CVerticalSteps from 'storybook-component-module/src/components/steps/CVerticalSteps'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  stepIconRoot: {
    color: `${theme.palette.basic?.grey} ! important`,
    opacity: 0.5,
  },
  stepIconRootActive: {
    color: `${theme.palette.text.primary} ! important`,
    opacity: 1,
  },
  stepIconRootCompleted: {
    fill: `${theme.palette.status?.info} ! important`,
    opacity: 1,
  },
  stepIconText: {
    fill: theme.palette.text?.main + ' !important',
    fontSize: `${theme.typography.h3.fontSize} !important`,
    fontFamily: `${theme.typography.h2.fontFamily} !important`,
  },
  label: {
    '& .MuiStepLabel-label': {
      paddingLeft: 32,
      fontWeight: 'bold',
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: `${theme.typography.body1.fontSize} !important`,
      color: theme.palette.text.disabled + ' !important',
    },
    '& .MuiStepLabel-label.Mui-active': {
      fontWeight: 'bold',
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: `${theme.typography.body1.fontSize} !important`,
      color: theme.palette.text.primary + ' !important',
    },
    '& .MuiStepLabel-label.Mui-completed': {
      fontWeight: 'bold',
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: `${theme.typography.body1.fontSize} !important`,
      color: theme.palette.text.disabled + ' !important',
    },
  },
  description: {
    paddingLeft: 32,
    whiteSpace: 'pre-line',
    color: theme.palette.text.primary,
    textAlign: 'left',
    fontFamily: `${theme.typography.body1.fontFamily} !important`,
    fontSize: `${theme.typography.body1.fontSize} !important`,
  },
}))

const VoskerStepper = ({ steps, validationCompletion, expandable, isCompleted, disabled, onNext }) => {
  const classes = useStyles()

  return (
    <CVerticalSteps
      steps={steps}
      expandable={expandable}
      validateCompletion={validationCompletion}
      isCompleted={isCompleted}
      disabled={disabled}
      onNext={onNext}
      className={classes}
    />
  )
}

export default VoskerStepper
