import { Box, CardMedia, IconButton, makeStyles, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ArrowLeft from 'camera-module/cameras/ui/cameras-page/icons/ArrowLeft'
import ArrowRight from 'camera-module/cameras/ui/cameras-page/icons/ArrowRight'
import WarningIcon from 'assets/icons/navigation/WarningIcon'
import VoskerNoImgIcon from '../../cameras-page/icons/VoskerNoImgIcon'

import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'
import { defaultTrackingProperties, Mixpanel } from 'vosker/src/Mixpanel'
import useUser from 'user-module/user/core/useUser'
import { useLoadCameras } from 'camera-module/cameras/core/useCameras'

const useStyles = makeStyles((theme) => ({

  media: {
    height: 145,
    position: 'relative',
    overflow: 'visible',
    borderRadius: 6,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  navigation: {
    position: 'absolute',
    top: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.only('xs')]: {
      top: '59%',
    },
  },
  arrowEnabled: {
    marginLeft: '4px',
    marginRight: '3px',
    width: '34px',
    height: '34px',
    position: 'relative',
  },
  arrow: {
    marginLeft: '4px',
    marginRight: '3px',
    width: '34px',
    height: '34px',
    top: '-2px',
    position: 'relative',
    '&[data-testid="arrow-left"]': {
      marginLeft: '5px',
    },
    '&[data-testid="arrow-left"]:hover::after': {
      content: '""',
      position: 'absolute',
      top: -2,
      left: '1px',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background?.shadow,
      zIndex: -1,
      borderRadius: '4px',
      opacity: 0.7,
    },

    '&[data-testid="arrow-right"]:hover::after': {
      content: '""',
      position: 'absolute',
      top: -2,
      left: '-3px',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background?.shadow,
      zIndex: -1,
      borderRadius: '4px',
      opacity: 0.7,
    },
    '&:hover': {
      background: 'none !important',
    },
  },
  photoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.only('sm')]: {
      alignItems: 'initial',
      width: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      alignItems: 'center',
      width: '100%',
    },
  },
  photo: {
    maxWidth: '100%',
    maxHeight: '100vh',
    borderRadius: '6px',
  },
  photoContainerEmpy: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textAlign: 'center',
  },
  photoText: {
    fontSize: '16px',
    color: theme.palette.text.secondary,
    fontFamily: 'exo2-regular',
    marginBottom: '12px',
    [theme.breakpoints.only('xs')]: {
      marginBottom: '1px',
    },
  },
  mediaEmpty: {
    height: 143,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette?.switch?.background,
    borderRadius: '6px',
    overflow: 'hidden',
    padding: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: 164,
    },
  },
  viewAllLabel: {
    position: 'absolute',
    zIndex: 10,
    top: '48%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.basic?.lightGrey,
    padding: '8px 16px',
    borderRadius: '4px',
    fontSize: '15px',
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'Exo 2',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('xs')]: {
      top: '57%',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(49, 50, 62, 0.75)',
    pointerEvents: 'none',
    [theme.breakpoints.down('xs')]: {
      height: '114%',
    },
  },
  noPlanContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    gap: theme.spacing(0.6),
  },
  planExpired: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2?.fontSize,
    fontWeight: 600,
  },
  link: {
    cursor: 'pointer',
    fontWeight: 600,
    color: theme.palette?.status?.info,
    fontSize: theme.typography?.caption1?.fontSize,
    fontFamily: theme.typography?.caption1?.fontFamily,
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  warningIcon: {
    width: 16,
  },
  dots: {
    position: 'absolute',
    bottom: 24,
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
    [theme.breakpoints.only('xs')]: {
      bottom: '2px',
    },
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: theme.palette.basic?.lightGrey,
    opacity: 0.75,
    transition: 'background-color 0.3s ease',
  },
  activeDot: {
    width: 8,
    height: 8,
    transform: 'translateY(-10%)',
    backgroundColor: theme.palette.basic?.white,
    opacity: 1,
  },
  noImgIcon: {
    fontSize: 40,
    marginBottom: 8,
    [theme.breakpoints.only('xs')]: {
      marginBottom: 1,
    },
  },
}))

const CameraPhotoGallery = ({ photos, cameraId, camera, handleRedirect, hasPlan, isPlanExpired }) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { photoId: currentPhotoId } = useParams()
  const [imageError, setImageError] = useState(false)
  const homePageEnabled = useHomePageFlag()
  const user = useUser()
  const cameras = useLoadCameras()

  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0)

  const extendedPhotos = photos?.length > 0
    ? [...photos, { ...photos[photos?.length - 1], isDuplicate: true }]
    : []

  useEffect(() => {
    if (Array.isArray(photos)) {
      const index = photos.findIndex((photo) => photo.id === currentPhotoId)
      if (index !== -1) {
        setCurrentPhotoIndex(index)
      }
    }
  }, [currentPhotoId, photos])

  const handleNextPhoto = async (event) => {
    event.stopPropagation()
    setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % extendedPhotos.length)

    await Mixpanel.track('Thumbnails Navigation', {
      'Camera Name': camera.config.name,
      Direction: 'Right',
      ...defaultTrackingProperties(user, cameras),
    })
    setImageError(false)
  }

  const handlePreviousPhoto = async (event) => {
    event.stopPropagation()
    setCurrentPhotoIndex((prevIndex) => (prevIndex - 1 + extendedPhotos.length) % extendedPhotos.length)

    await Mixpanel.track('Thumbnails Navigation', {
      'Camera Name': camera.config.name,
      Direction: 'Left',
      ...defaultTrackingProperties(user, cameras),
    })
  }

  const handleRedirectionToPlanPage = async () => {
    handleRedirect(`/camera/${camera.id}/plan`)
    await Mixpanel.track('Thumbnail Renew Plan Clicked', {
      'Camera Name': camera.config.name,
      ...defaultTrackingProperties(user, cameras),
    })
    setImageError(false)
  }

  const handleImageError = () => {
    setImageError(true)
  }

  if (isPlanExpired && homePageEnabled) {
    return (
      <CardMedia
        className={classes.mediaEmpty}
        data-testid="plan-expired-container"
      >
        <Box className={classes.noPlanContainer}>
          <WarningIcon className={classes.warningIcon} />
          <Typography className={classes.planExpired} data-testid="plan-expired">
            { t('no_plan_expiration.expired') }
          </Typography>
        </Box>
        <Typography className={classes.link} onClick={() => camera?.id && handleRedirectionToPlanPage()}>
          { t('no_plan_expiration.chooseAPlanButton') }
        </Typography>
      </CardMedia>
    )
  }

  const handleImageClick = async (photoId) => {
    history.push(`/camera/${cameraId}/photo/${photoId}`, { fromPage: 'photoGallery' })
    await Mixpanel.track('Thumbnail Clicked', {
      'Camera Name': camera.config.name,
      'Thumbnail Position': photos.indexOf(photos.find(p => p.id === photoId)) + 1,
      ...defaultTrackingProperties(user, cameras),
    })
  }

  const handleRedirectionToGallery = async (event) => {
    event.stopPropagation()
    handleRedirect(`/camera/${cameraId}`)
    await Mixpanel.track('Thumbnail View All Clicked', {
      'Camera Name': camera.config.name,
      ...defaultTrackingProperties(user, cameras),
    })
  }

  return photos?.length > 0
    ? (
      <CardMedia
        className={classes.media}
        onClick={() => {
          if (
            !extendedPhotos[currentPhotoIndex]?.isDuplicate ||
            currentPhotoIndex !== extendedPhotos.length - 1
          ) {
            handleImageClick(extendedPhotos[currentPhotoIndex]?.id)
          }
        }}
      >
        <Box className={classes.photoContainer}>
          { extendedPhotos[currentPhotoIndex]?.isDuplicate && <div className={classes.overlay} /> }
          { imageError
            ? (
              <Box className={classes.mediaEmpty}>
                <VoskerNoImgIcon className={classes.noImgIcon} data-testid="VoskerNoImgIcon" />
              </Box>
              )
            : (
              <img
                className={classes.photo}
                src={extendedPhotos[currentPhotoIndex]?.hdStatus === 'available' ? extendedPhotos[currentPhotoIndex]?.urls.hd : extendedPhotos[currentPhotoIndex]?.urls.large}
                alt={`Event ${currentPhotoIndex + 1}`}
                onError={handleImageError}
              />
              ) }
          { extendedPhotos[currentPhotoIndex]?.isDuplicate && (
            <Typography
              className={classes.viewAllLabel}
              onClick={handleRedirectionToGallery}
              component="span"
              data-testid="view-all-label"
            >
              { t('app:menu.event') }
            </Typography>
          ) }
          <Box className={classes.dots}>
            { Array.from({ length: Math.min(photos.length, 3) + 1 }).map((_, index) => (
              <Box
                key={index}
                className={`${classes.dot} ${
          index === currentPhotoIndex ? classes.activeDot : ''
                }`}
                data-testid={index === currentPhotoIndex ? 'active-dot' : 'dot'}
              />
            )) }
          </Box>
        </Box>

        <Box className={classes.navigation}>
          <IconButton
            className={
              currentPhotoIndex === 0 || extendedPhotos.length === 1
                ? classes.arrowEnabled
                : classes.arrow
            }
            data-testid="arrow-left"
            onClick={handlePreviousPhoto}
            disabled={currentPhotoIndex === 0 || extendedPhotos.length === 1}
          >
            <ArrowLeft
              color={currentPhotoIndex === 0 || extendedPhotos.length === 1 ? 'gray' : 'white'}
            />
          </IconButton>
          <IconButton
            className={
              currentPhotoIndex === extendedPhotos.length - 1
                ? classes.arrowEnabled
                : classes.arrow
            }
            data-testid="arrow-right"
            onClick={handleNextPhoto}
            disabled={currentPhotoIndex === extendedPhotos.length - 1}
          >
            <ArrowRight
              color={currentPhotoIndex === extendedPhotos.length - 1 ? 'gray' : 'white'}
            />
          </IconButton>
        </Box>
      </CardMedia>
      )
    : (
      <CardMedia className={classes.mediaEmpty} title="No Event Available">
        <Box className={classes.photoContainerEmpty}>
          <Box className={classes.photoText}>{ t('photo_gallery.no_events') }</Box>
        </Box>
      </CardMedia>
      )
}

export default CameraPhotoGallery
