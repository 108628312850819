import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import Form from 'form-module/ui'
import { SUCCESS } from 'shared-module/message/messageTypes'
import useUserActions from 'user-module/user/core/useUserActions'
import PasswordValidationForm from 'user-module/account/management/PasswordValidationForm'

const PasswordValidationStep = ({ onSubmit, disabled }) => {
  const { t } = useTranslation()
  const { validatePassword } = useUserActions()

  const initialValues = { password: '' }
  const formValidations = Yup.object().shape({ password: Yup.string().required() })

  const onFormikSubmit = (data, { setFieldError }) => {
    validatePassword(data.password).then(response => response.messageType.match(SUCCESS)
      ? onSubmit()
      : setFieldError('password', t('input.password_not_match')))
  }

  return (
    <Form
      data={initialValues}
      validateOnBlur={false}
      onSubmit={onFormikSubmit}
      id="validation-password-form"
      validationSchema={formValidations}
    >
      <PasswordValidationForm disabled={disabled} />
    </Form>
  )
}

export default PasswordValidationStep
