import { useTranslation } from 'react-i18next'
import { formatDateTime } from 'shared-module/common-utils'
import CameraStatusTile from 'camera-module/camera/ui/camera-status-page/CameraStatusTile'
import LastSync from 'assets/icons/status/LastSync'

const LastSyncTile = ({ value }) => {
  const { i18n } = useTranslation()
  return <CameraStatusTile label="app:camera.last_communication" icon={<LastSync />} value={formatDateTime(value, i18n).toUpperCase()} useSVGIcon />
}

export default LastSyncTile
