import withStyles from '@material-ui/core/styles/withStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MuiTooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import { alpha } from '@mui/system'

const Tooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 'none',
    boxShadow: theme.shadows[1],
    fontSize: '1em',
  },
}))(MuiTooltip)

const useStyles = makeStyles(theme => ({
  infoIcon: {
    color: alpha(theme.palette.secondary.main, 0.7),
    fontSize: '1em',
    display: 'inline-block',
    marginLeft: '.5rem',
    fill: theme.palette.icon?.primary + ' !important',
  },
}))

const LabelledInput = (props) => {
  const classes = useStyles(props)
  return (
    <label>
      <Grid container>
        <Grid item xs={props?.isAligned ? 7 : 12} md={6}>
          <Box pt={1}>
            <Typography>
              { props.label }
              { props.help && (
                <Tooltip title={props.help} arrow className={classes.infoIcon}>
                  <InfoRoundedIcon />
                </Tooltip>
              ) }
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={props?.isAligned ? 5 : 12} md={6} onClick={props.onClick}>
          { props.children }
        </Grid>
      </Grid>
    </label>
  )
}

export default LabelledInput
