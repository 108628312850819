import { useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import usePhotosActionsOld from 'photo-module/photos/core/usePhotosActionsOld'
import usePhotoViewerNavigation from 'photo-module/photo/core/usePhotoViewerNavigation'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import usePhotoActions from 'photo-module/photo/core/usePhotoActions'
import DeletePhotoDialog from '../../../../photos/ui/photos-gallery/actions-buttons/DeletePhotoDialog'
import TrashActiveIcon from 'vosker/src/assets/icons/viewer/TrashActiveIcon'
import TrashInactiveIcon from 'vosker/src/assets/icons/viewer/TrashInactiveIcon'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  disabled: {
    '& .MuiIconButton-root.Mui-disabled': {
      '&:hover': {
        backgroundColor: 'none',
      },
    },
  },
}))

const DeleteButton = ({ setIsDisabled = () => {} }) => {
  const classes = useStyles()
  const photo = useSelectedPhoto()
  const photosAction = usePhotosActionsOld(photo.cameraId)
  const photoActions = usePhotoActions()
  const { previousPhoto, nextPhoto, switchPhoto } = usePhotoViewerNavigation(photo.id)
  const dialogActions = useConfirmationDialogActions()
  const cantDeletePhoto = photoActions.cantDeletePhoto(photo)
  const shared = JSON.parse(localStorage.getItem('isShared'))
  const disabled = cantDeletePhoto || shared

  useEffect(() => {
    photo && setIsDisabled(cantDeletePhoto)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo])

  const deletePhoto = () => {
    dialogActions.close()
    photosAction.deletePhotos([photo.id])
    switchPhoto(nextPhoto || previousPhoto)
  }

  return (
    <>
      <IconButton
        color="secondary"
        disabled={disabled}
        onClick={dialogActions.open}
        className={disabled ? classes.disabled : ''}
      >
        { disabled ? <TrashInactiveIcon /> : <TrashActiveIcon /> }
      </IconButton>

      <DeletePhotoDialog
        open={dialogActions.isOpen}
        onClose={dialogActions.close}
        onConfirm={deletePhoto}
        photo={photo}
      />
    </>
  )
}

export default DeleteButton
