import { useTranslation } from 'react-i18next'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useVideoActions from 'video-module/video-clip/core/useVideoActions'
import downloadStatus from 'photo-module/download/downloadStatus'
import { defaultTrackingProperties, Mixpanel } from 'vosker/src/Mixpanel'
import useUser from 'user-module/user/core/useUser'
import { useCameras } from 'camera-module/cameras/core/useCameras'

const DialogFullHdVideoRequest = ({ open, onClose, remainingVideos }) => {
  const { t } = useTranslation()
  const actions = useVideoActions()
  const photo = useSelectedPhoto()
  const user = useUser()
  const camera = useSelectedCamera()
  const cameras = useCameras()

  const acknowledgeRequest = async () => {
    onClose()

    if (photo.hdVideoStatus === downloadStatus.error) {
      await actions.clearHdVideo(photo.cameraId, photo.id)
    }

    await actions.requestHdVideo(camera.id, photo.id)

    await Mixpanel.track('HD Confirmed', {
      'Camera Model': camera.config.model,
      'Camera Plan': camera.subscription.plan.name,
      'HD Videos Left': remainingVideos - 1,
      'Media Type': 'VIDEO',
      ...defaultTrackingProperties(user, cameras),
    })
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('photo_viewer.request_full_hd_video.title')}
      text={t('photo_viewer.request_full_hd_video.text', { remaining: remainingVideos })}
      confirmText={t('photo_viewer.request_full_hd_video.confirm')}
      onConfirm={acknowledgeRequest}
      onCancel={onClose}
    />
  )
}

export default DialogFullHdVideoRequest
