import CameraStatusTile from 'camera-module/camera/ui/camera-status-page/CameraStatusTile'
import Coordinates from 'assets/icons/status/Coordinates'
import Copy from 'assets/icons/status/Copy'
import Grid from '@material-ui/core/Grid'
import useMessageActions from 'shared-module/message/useMessageActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import MuiTooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

const Tooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 'none',
    boxShadow: theme.shadows[1],
    fontSize: '1em',
    marginTop: '6px',
  },
}))(MuiTooltip)

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: 'max-content',
    marginTop: '-9px',
    marginBottom: '-11px',
  },
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    top: '40%',
    left: '25%',
    transform: 'translateX(-50%)',
    marginTop: '8px',
  },
  button: {
    margin: '2px 8px',
    padding: 0,
    minWidth: 'auto',
    width: 24,
    height: 24,
  },
  icon: {
    marginTop: '4px !important',
  },
  copyIcon: {
    width: '16px !important',
    height: '16px !important',
  },
}))

const CoordinatesTile = ({ value }) => {
  const { t } = useTranslation()
  const { showSuccess } = useMessageActions()
  const classes = useStyles()
  const handleCopy = () => {
    const textToCopy = `${value.latitude}, ${value.longitude}`
    navigator.clipboard.writeText(textToCopy)
    showSuccess('camera.copy')
  }

  return (
    <CameraStatusTile
      label="camera.coordinates"
      icon={<Coordinates className={classes.icon} />}
      value={(
        <Grid container className={classes.gridContainer}>

          <Grid item style={{ marginTop: 5 }}> { `${value.latitude}, ${value.longitude}` }
            <Tooltip title={t('camera.copy_coordinates')} arrow>

              <Button
                onClick={handleCopy}
                className={classes.button}
              >
                <Box mt="7px">
                  <Copy className={classes.copyIcon} />
                </Box>
              </Button>
            </Tooltip>
          </Grid>

        </Grid>
      )}
      useSVGIcon
    />
  )
}

export default CoordinatesTile
