const init = () => ({ fetching: false })
const set = purposes => ({ fetching: false, purposes: purposes })
const fetching = () => ({ fetching: true })

const isReady = state => isFetching(state) || hasPurposes(state?.purposes)
const isFetching = state => state?.fetching === true
const hasPurposes = purposes => purposes !== undefined

const cameraPurposesState = { init, fetching, set, isReady, isFetching, hasPurposes }
export default cameraPurposesState
