import { Link } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { ChevronRight } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'

import ProfileSelect from 'shared-module/user/ProfileSelect'
import useCameraModels from 'camera-module/model/core/useCameraModels'
import DocumentIcon from 'vosker/src/assets/icons/DocumentIcon'
import VoskerTooltip from 'vosker/src/components/VoskerTooltip'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import VoskerInputField, { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import CameraMessage from 'camera-module/cameras/ui/cameras-page/camera-message/CameraMessage'
import useCameraPurposes from 'camera-module/purpose/core/useCameraPurposes'
import CameraActivationEmptyIcon from 'vosker/src/assets/icons/CameraActivationEmptyIcon'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import helper from 'vosker/src/camera-module/camera/ui/camera-activation/cameraActivationHelper'
import { useLoadCameras } from 'camera-module/cameras/core/useCameras'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 22,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 48,
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    borderRight: `1px solid ${theme.palette.border?.divider}`,
    gap: '1.5rem',
  },
  modelIcon: {
    position: 'absolute',
    width: 'fit-content',
    height: 24,
    bottom: '0',
  },
  modelTypography: {
    fontFamily: theme.typography.fontFamily,
    textTransform: 'capitalize',
    paddingTop: '4px',
  },
  activationCardContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  activationCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    gap: '1rem',
  },
  cameraNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    gap: '0.25rem',
  },
  subtitle: {
    fontWeight: 600,
  },
  message: {
    color: theme.palette.text.secondary,
  },
  infoIcon: {
    width: '1.125rem',
    paddingBottom: '0.188rem',
    marginLeft: '0.375rem',
    fill: theme.palette.border?.secondary + ' !important',
  },
  link: {
    fontWeight: 600,
    color: theme.palette.status?.info,
    textDecoration: 'none !important',
  },
  linkIcon: {
    fill: theme.palette.status?.info + '!important',
    paddingBottom: 3,
    marginRight: '0.375rem',
  },
  activationHelpWrapper: {
    width: 338,
    height: 465,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    textAlign: 'center',
    flexDirection: 'column',
  },
  emptyIcon: {
    '&.MuiSvgIcon-root': {
      width: '100% !important',
      height: '100% !important',
      fill: theme.palette.border?.primary + ' !important',
    },
  },
  helpImage: {
    minWidth: '100%',
    minHeight: '100%',
    margin: '0 !important',
  },
  select: {
    '&.MuiListItem-button': {
      '&:hover': {
        backgroundColor: theme.palette.background?.empty,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.border?.primary,
      },
    },
  },
  purposeSelector: {
    '& .MuiMenu-paper': {
      maxHeight: '300px !important',
      scrollbarColor: `${theme.palette.border?.secondary} transparent`,
      scrollbarWidth: 'thin',
    },
  },
  messageCard: {
    marginTop: '0 !important',
  },
  elevation: {
    '& .MuiPaper-elevation8': {
      marginTop: 8,
      boxShadow: `0px 4px 16px 0px ${theme.palette.background?.paperShadow}`,
    },
    '& .MuiPaper-rounded': {
      borderRadius: '0.375rem',
    },
  },
  inputIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 'xx-large !important',
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 16px) !important',
    },
  },
}))

const CameraActivationForm = ({ allFieldsAreChanged, setAllFieldsAreChanged, exitActivation }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const formik = useFormikContext()
  const cameras = useLoadCameras()
  const cameraModels = useCameraModels()
  const cameraPurposes = useCameraPurposes()
  const importedClasses = VoskerInputStyles()

  const [purposeItems, setPurpose] = useState([])
  const [modelItems, setModelItem] = useState([])
  const [previousValues, setPreviousValues] = useState(helper.getInitialValues())
  const [shouldDisplayVerifyCardMessage, setShouldDisplayVerifyCardMessage] = useState(false)

  const quickGuidePath = 'https://www.vosker.com/support/vkx-quick-start-guide/'

  const CameraModelItem = (model) => (
    <Box display="flex" alignItems="center">
      <Box position="relative" height="24px" width="24px" ml={0.5} mr={1} overflow="hidden">
        <img alt={model.name} src={model.icon} className={classes.modelIcon} />
      </Box>
      <CTypography variant="body1" className={classes.modelTypography}>{ model.name }</CTypography>
    </Box>
  )

  useEffect(() => {
    if (cameraModels) {
      setModelItem(
        Object.values(cameraModels)
          .reverse()
          .map(model => model)
          .map(model => ({
            key: model.name,
            value: model.name,
            children: CameraModelItem(model),
          })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraModels])

  useEffect(() => {
    if (cameraPurposes) {
      setPurpose(
        Object.values(cameraPurposes)
          .map(purpose => purpose)
          .map(purpose => ({
            key: purpose,
            value: purpose,
            name: t('app:active_device.form_layout.camera_purpose_options.' + purpose),
          })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraPurposes])

  useEffect(() => {
    if (helper.isCameraModelSelected(formik)) {
      setShouldDisplayVerifyCardMessage(helper.shouldDisplayVerifyCardMessage(formik))

      if (cameras && !helper.isCameraNameEdited(formik, cameras, previousValues)) {
        formik.setFieldValue('deviceName', helper.generatorDefaultCameraName(formik.values, cameras))
        setPreviousValues(formik.values)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.deviceModel])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => helper.formatsActivationCode(formik), [formik.values.activationCode])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setAllFieldsAreChanged(helper.allFieldAreChanged(formik)), [formik])

  if (!cameraModels) return null

  return (
    <Box>
      <CTypography variant="h2" className={classes.title}>{ t('app:active_device.form_layout.title') }</CTypography>

      <Box display="flex" mt="1rem">
        <CTypography variant="caption1" className={classes.message}>{ t('app:active_device.form_layout.subtitle') }</CTypography>

        <VoskerTooltip title={t('app:active_device.form_layout.tooltip')} arrow>
          <InfoRoundedIcon className={clsx(classes.infoIcon, classes.titleTooltipIcon)} />
        </VoskerTooltip>
      </Box>

      <Grid container className={classes.mainContainer}>
        <Grid container item xs={6} className={classes.fieldContainer}>
          <Box component={Grid} item xs={10} flexBasis="content">
            <ProfileSelect
              required
              name="deviceModel"
              iconItems={modelItems}
              formik={formik}
              form="camera-activation"
              label={t('app:active_device.form_layout.camera_models')}
              className={clsx(importedClasses.input, classes.inputIcon)}
              menuClassName={classes.select}
              paperClassName={classes.elevation}
            />
          </Box>

          <Box component={Grid} container item xs={10} flexBasis="content" className={classes.activationCodeContainer}>
            <Box display="flex">
              <CTypography variant="label" className={classes.subtitle}>
                { t('app:active_device.form_layout.activation_code_subtitle') }
              </CTypography>

              <VoskerTooltip title={t('app:active_device.form_layout.activation_code_tooltip')} arrow>
                <InfoRoundedIcon className={classes.infoIcon} />
              </VoskerTooltip>
            </Box>

            <VoskerInputField
              required
              name="activationCode"
              label={t('app:active_device.form_layout.activation_code')}
              showErrorIcon
              maxLength={13}
            />
          </Box>

          <Grid container item xs={10} className={classes.cameraNameContainer}>

            <CTypography variant="label" className={classes.subtitle}>
              { t('app:active_device.form_layout.camera_name_subtitle') }
            </CTypography>

            <CTypography variant="caption1" className={classes.message} marginBottom="1rem">
              { t('app:active_device.form_layout.camera_name_message') }
            </CTypography>

            <VoskerInputField
              required
              name="deviceName"
              label={t('app:active_device.form_layout.camera_name')}
              showErrorIcon
            />
          </Grid>

          <Grid container item xs={10} className={classes.activationCodeContainer}>
            <CTypography variant="label" className={classes.subtitle}>
              { t('app:active_device.form_layout.camera_purpose_subtitle') }
            </CTypography>

            <ProfileSelect
              required
              name="purpose"
              items={purposeItems}
              formik={formik}
              form="camera-activation"
              label={t('app:active_device.form_layout.camera_purpose')}
              className={clsx(importedClasses.input, classes.inputIcon)}
              paperClassName={clsx(classes.purposeSelector, classes.elevation)}
              menuClassName={classes.select}
            />
          </Grid>

          { shouldDisplayVerifyCardMessage && (
            <Grid item xs={10}>
              <CameraMessage
                severity="warning"
                title={t('app:active_device.form_layout.verify_card.title')}
                message={t('app:active_device.form_layout.verify_card.message')}
                className={classes.messageCard}
              />
            </Grid>
          ) }

          { helper.shouldDisplayQuickGuide(formik) && (
            <Box display="flex">
              <DocumentIcon className={classes.linkIcon} />
              <Link to={{ pathname: quickGuidePath }} target="_blank" className={classes.link}>{ t('app:active_device.form_layout.quick_guide') }</Link>
              <ChevronRight className={classes.linkIcon} />
            </Box>
          ) }
        </Grid>

        <Grid container item xs={6} className={classes.activationCardContainer}>
          <Box component={Grid} item xs="auto" display="flex" alignItems="center">
            <Box className={classes.activationHelpWrapper}>
              { helper.isCameraModelSelected(formik)
                ? <img src={cameraModels[formik.values.deviceModel]?.helpImage} alt="" className={classes.helpImage} />
                : <CameraActivationEmptyIcon className={classes.emptyIcon} /> }
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container style={{ position: 'relative', top: '5.25rem', left: '40rem' }}>
        <Grid item xs={12} sm="auto" style={{ paddingRight: '1rem' }}>
          <VoskerSecondaryButton onClick={exitActivation} style={{ width: '100%', minWidth: '7.25rem' }}>
            { t('button.cancel') }
          </VoskerSecondaryButton>
        </Grid>

        <Grid item xs={12} sm="auto">
          <VoskerPrimaryButton
            color="primary"
            type="submit"
            style={{ width: '100%', minWidth: '7.25rem' }}
            disabled={!allFieldsAreChanged || !formik.isValid}
          >
            { t('button.next') }
          </VoskerPrimaryButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CameraActivationForm
