import React, { useState } from 'react'

import GalleryOnDemand from './GalleryOnDemand'

const GalleryOnDemandWrapper = ({ camera }) => {
  const [requestPending, setRequestPending] = useState(false)
  const [showOndemand, setShowOnDemand] = useState(false)
  const [noAlerts, setNoAlerts] = useState(false)
  const [cameraNotAvailable, setCameraNotAvailable] = useState(false)

  return (
    <GalleryOnDemand
      camera={camera}
      requestPending={requestPending}
      setRequestPending={setRequestPending}
      showOndemand={showOndemand}
      setShowOnDemand={setShowOnDemand}
      noAlerts={noAlerts}
      setNoAlerts={setNoAlerts}
      cameraNotAvailable={cameraNotAvailable}
      setCameraNotAvailable={setCameraNotAvailable}
    />
  )
}

export default GalleryOnDemandWrapper
