import { useTranslation } from 'react-i18next'

import Head from 'shared-module/new-components/head/Head'
import Title from 'shared-module/new-components/layout/Title'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import ChangePasswordForm from 'user-module/user/ui/change-password/components/ChangePasswordForm'
import ChangePasswordSuccess from 'user-module/user/ui/change-password/components/ChangePasswordSuccess'

const ChangePasswordPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Head title={t('app:change_password.head.title')} />
      <Title>{ t('change_password.title') }</Title>
      <FormDataContext>
        <ChangePasswordForm />
        <ChangePasswordSuccess />
      </FormDataContext>
    </>
  )
}

export default ChangePasswordPage
