import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import cameraApi from 'camera-module/camera/api/cameraApi'
import { useTranslation } from 'react-i18next'

const CameraSettingsResetDialog = ({ camera, onResetSettings, onClose }) => {
  const { t } = useTranslation()

  const resetCameraSetting = () => {
    cameraApi.resetSettings(camera.id)
      .then(cameraSettingReset => onResetSettings(cameraSettingReset))
    onClose()
  }

  return (
    <ConfirmationDialog
      open
      onClose={onClose}
      title={t('camera.settings.resetSettings.title', { name: camera.config.name })}
      onConfirm={resetCameraSetting}
      confirmText={t('camera.settings.buttons.reset')}
      onCancel={onClose}
    />
  )
}

export default CameraSettingsResetDialog
