import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import VoskerLogoLight from 'vosker/src/assets/icons/VoskerLogoLight'
import useLanguage from 'shared-module/components/language/useLanguage'

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))

const VoskerPublicPageBanner = () => {
  const classes = useStyles()
  const language = useLanguage()

  return (
    <Link href={`https://www.vosker.com/?lang=${language}`}>
      <Box p={3} className={classes.box} display="flex">
        <VoskerLogoLight style={{ width: '200px', height: '45px', margin: 'auto' }} />
      </Box>
    </Link>
  )
}

export default VoskerPublicPageBanner
