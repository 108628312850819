import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import usePhotosDelete from 'photo-module/photos/ui/photos-gallery/usePhotosDelete'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  button: {
    height: '2.5rem',
    minHeight: '2.5rem',
  },
  selectedItem: {
    margin: '0 1rem',
    lineHeight: '1.3125rem',
    color: theme.palette.text.secondary,
  },
}))

const DeleteMultiplePhotos = ({ handleDelete, disabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { multiSelectEnabled, toggleMultiSelect, photoIdsToDelete, photosToDeleteActions } = usePhotosDelete()

  const toggleActive = () => {
    toggleMultiSelect()
    photosToDeleteActions.reset()
  }

  const renderSelectButton = () => {
    return (
      <Grid container display="flex" flexDirection="row" justifyContent="flex-end" spacing={1} style={{ marginBottom: '1rem' }}>
        <Grid item>
          <VoskerSecondaryButton
            size="large"
            id="activeMultiDelete"
            onClick={toggleActive}
            className={classes.button}
            disabled={disabled}
          >
            { t('button.select') }
          </VoskerSecondaryButton>
        </Grid>
      </Grid>
    )
  }

  const renderActionButtons = () => {
    return (
      <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" spacing={1} style={{ marginBottom: '1rem' }}>
        <Grid item>
          { photoIdsToDelete?.length !== 0 &&
            <Typography variant="body1" className={classes.selectedItem}> { t('app:photo.counter', { count: photoIdsToDelete?.length }) }</Typography> }
        </Grid>

        <Grid item>
          <VoskerSecondaryButton
            id="cancelButton"
            onClick={toggleActive}
            size="large"
            className={classes.button}
          >
            { t('button.cancel') }
          </VoskerSecondaryButton>
        </Grid>
        <Grid item>
          <VoskerPrimaryButton
            id="deleteButton"
            disabled={photoIdsToDelete.length === 0}
            onClick={() => handleDelete()}
            size="large"
            className={classes.button}
          >
            { t('button.delete') }
          </VoskerPrimaryButton>
        </Grid>
      </Grid>
    )
  }

  return multiSelectEnabled
    ? <>{ renderActionButtons() }</>
    : <>{ renderSelectButton() }</>
}

export default DeleteMultiplePhotos
