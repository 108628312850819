import { useDispatch } from 'react-redux'
import userActions from 'vosker/src/user-module/user/core/userActions'

const useUserActions = () => {
  const dispatch = useDispatch()

  return {
    register: user => dispatch(userActions.register(user)),
    reload: user => dispatch(userActions.reload(user.id)),
    login: (email, password) => dispatch(userActions.loginUser(email, password)),
    autologin: (token, refresh) => dispatch(userActions.login(token, refresh)),
    logout: () => dispatch(userActions.logout()),

    deleteUser: () => dispatch(userActions.deleteUser()),
    validatePassword: password => dispatch(userActions.validatePassword(password)),
    updatePassword: (password, newPassword) => dispatch(userActions.updatePassword(password, newPassword)),
    update: user => dispatch(userActions.update(user)),
    updateExtended: user => dispatch(userActions.updateExtended(user)),
  }
}

export default useUserActions
