import { useMediaQuery } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import useTheme from '@material-ui/core/styles/useTheme'

import FullScreenBackground from 'shared-module/new-components/layout/FullScreenBackground'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '508px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  box: {
    backgroundColor: theme.palette.background.default,
  },
}))

const PublicPageLayout = ({ Banner, children }) => {
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))
  const classes = useStyles()

  return (
    <FullScreenBackground image="">
      <Container
        component="main"
        disableGutters
        className={classes.container}
        style={{ height: breakpoint ? '100vh' : 'undefined' }}
      >
        <Box height="auto" overflow="hidden auto" borderRadius="0.375rem">
          <Banner />
          <Box justifyContent="center" className={classes.box}>
            <Box display="flex" margin="0 auto" flexDirection="column" flexGrow={1} pt={3} pb={3} maxWidth={400} minHeight={320}>
              { children }
            </Box>
          </Box>
        </Box>
      </Container>
    </FullScreenBackground>
  )
}

export default PublicPageLayout
