import { useState } from 'react'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import Backdrop from '@mui/material/Backdrop'
import { makeStyles } from '@material-ui/core/styles'
import useTheme from '@material-ui/core/styles/useTheme'
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import ProvinceSelectedIcon from 'camera-module/cameras/ui/cameras-page/icons/ProvinceSelectedIcon'
import ProvinceSelectionIcon from 'camera-module/cameras/ui/cameras-page/icons/ProvinceSelectionIcon'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import ProfileTerritory from 'shared-module/user/ProfileTerritory'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background?.consent} 0%, ${theme.palette.background?.gradient} 100%)`,
    padding: 20,
    borderRadius: 6,
    minHeight: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContent: {
    padding: '12px !important',
    textAlign: 'left',
    flexGrow: 1,
    transition: 'opacity 200ms ease-in-out',
    minHeight: 400,
  },
  dialogAction: {
    padding: '12px !important',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
  icon: {
    width: 139,
    height: 160,
    overflow: 'visible',
  },
  provinceSelected: {
    transform: 'translateX(-14px)',
  },
  title: {
    '&.MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: theme.typography.h6?.fontSize,
      fontWeight: 600,
      fontFamily: theme.typography.h6?.fontFamily,
    },
  },
  text: {
    '&.MuiTypography-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1?.fontSize,
      fontWeight: 400,
      fontFamily: theme.typography.body1?.fontFamily,
    },
    textAlign: 'left',
  },
  select: {
    marginTop: 5,
    width: '100%',
    backgroundColor: theme.palette.background?.paper,
    borderColor: theme.palette.background?.light,
    '&.MuiInputBase-root': {
      color: 'theme.palette.text.secondary',
    },
  },
  confirmButton: {
    width: '40%',
    marginRight: 2,
  },
  dropDown: {
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: theme.palette.background?.paper,
    '&:focus': {
      backgroundColor: theme.palette.background?.dropdown,
    },
    '&:hover': {
      backgroundColor: theme.palette.background?.empty,
    },
  },
  fadeContent: {
    opacity: 1,
    transition: 'opacity 200ms ease-in-out',
  },
  fadingContent: {
    opacity: 0,
  },
}))

const MarketingConsentProvince = ({ open, onClose, onSubmitSuccess }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const formik = useFormikContext()
  const inputClasses = VoskerInputStyles()
  const [isConfirmationView, setIsConfirmationView] = useState(false)
  const [isFading, setIsFading] = useState(false)

  const handleProvinceUpdate = async () => {
    if (formik.values.territory) {
      setIsFading(true)
      await formik.handleSubmit()

      await new Promise(resolve => setTimeout(resolve, 200))
      setIsConfirmationView(true)

      await new Promise(resolve => setTimeout(resolve, 50))
      setIsFading(false)
      if (onSubmitSuccess) {
        onSubmitSuccess()
      }
    }
  }

  const paperProps = {
    style: {
      maxWidth: 410,
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.background?.consent} 0%, ${theme.palette.background?.gradient} 100%)`,
      borderRadius: 6,
      minHeight: 520,
    },
  }

  const slotProps = {
    backdrop: { sx: { background: theme.palette.background?.shadow + 'B8' } },
  }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose(event, reason)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={paperProps}
      slots={{ backdrop: Backdrop }}
      slotProps={slotProps}
    >
      <Box className={classes.dialogWrapper}>
        <DialogContent className={clsx(
          classes.dialogContent,
          classes.fadeContent,
          { [classes.fadingContent]: isFading },
        )}
        >
          <Box className={classes.iconWrapper}>
            { isConfirmationView
              ? (
                <ProvinceSelectedIcon
                  className={clsx(classes.icon, { [classes.provinceSelected]: isConfirmationView })}
                />
                )
              : (
                <ProvinceSelectionIcon className={classes.icon} />
                ) }
          </Box>

          <Typography className={classes.title} variant="h6">
            { isConfirmationView
              ? t('app:marketing_consent.province_saved')
              : t('app:marketing_consent.province_title') }
          </Typography>

          <Typography className={classes.text} variant="body1" sx={{ mt: 0.45, mb: isConfirmationView ? 1.5 : 3 }}>
            { isConfirmationView
              ? t('app:marketing_consent.province_saved_text')
              : t('app:marketing_consent.province_text') }
          </Typography>

          { !isConfirmationView && (
            <Box className={classes.select}>
              <ProfileTerritory
                required
                name="territory"
                form="mc-province-selection"
                formik={formik}
                label={t('app:marketing_consent.province_selection')}
                showErrorIcon
                className={inputClasses.input}
                menuClassName={classes.dropDown}
              />
            </Box>
          ) }
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <VoskerPrimaryButton
            noWrap
            size="large"
            type={isConfirmationView ? 'submit' : 'button'}
            disabled={!isConfirmationView && !formik.values.territory}
            className={classes.confirmButton}
            onClick={isConfirmationView ? onClose : handleProvinceUpdate}
            data-testid={isConfirmationView ? 'confirm-button' : undefined}
            path={isConfirmationView ? '/' : undefined}
          >
            { isConfirmationView
              ? t('app:marketing_consent.gotit_button')
              : t('app:marketing_consent.confirm_button') }
          </VoskerPrimaryButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default MarketingConsentProvince
