import React from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

const CameraSubmission = () => {
  const { t } = useTranslation()
  const { values, initialValues } = useFormikContext()
  const disabled = values === initialValues

  return (
    <VoskerPrimaryButton color="primary" disabled={disabled} type="submit">
      { t('add_camera.create') }
    </VoskerPrimaryButton>
  )
}

export default CameraSubmission
