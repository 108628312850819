import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import { isExternalWebApp } from 'shared-module/webview/isExternalWebApp'
import NotificationsCenter from 'notifications-module/ui/NotificationsCenter'
import switches from 'shared-module/config/switches'
import appConfig from 'shared-module/config/appConfig'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -8,
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  appBar: {
    background: theme.palette.background.default,
    marginTop: '0px',
    zIndex: '101',
    height: '64px!important',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
})

const Navbar = ({ onClick, classes, Logo, BackButton, CartButton, notificationIcon, notificationIconActive, overrideClasses = undefined }) => {
  const isWebBrowser = !isExternalWebApp()
  const displayCart = isWebBrowser && CartButton

  const toggleMainMenu = () => onClick()

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={clsx(classes.appBar, overrideClasses?.navbar)}>
        <Toolbar style={{ top: 0 }}>
          { isWebBrowser && (
            <IconButton
              id="main-menu-button"
              className={classes.menuButton}
              aria-label="Menu"
              onClick={toggleMainMenu}
            >
              <MenuIcon data-testid="MenuIcon" />
            </IconButton>
          ) }
          { isExternalWebApp() && BackButton && <BackButton /> }

          <Box className={clsx(classes.grow, overrideClasses?.logo)}>
            <Logo
              style={{
                width: '100%',
                maxWidth: '150px',
                height: 'auto',
                marginTop: '2px',
                flexGrow: 1,
              }}
            />
          </Box>

          <Box display="flex">
            { appConfig.notificationCenter.switch === switches.on && (
              <NotificationsCenter
                notificationIcon={notificationIcon}
                notificationIconActive={notificationIconActive}
              />
            ) }
            { displayCart && <CartButton /> }
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default withStyles(styles)(Navbar)
