import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Prompt, useHistory } from 'react-router-dom'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const RouteLeavingGuard = ({ when, title, message }) => {
  const { t } = useTranslation()
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const history = useHistory()

  const closeModal = () => {
    setConfirmationOpen(false)
  }

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation) {
      setConfirmationOpen(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    closeModal()
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={closeModal}
        title={title}
        text={message}
        confirmText={t('button.confirm')}
        onConfirm={handleConfirmNavigationClick}
        onCancel={closeModal}
      />
    </>
  )
}

export default RouteLeavingGuard
