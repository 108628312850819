import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CellularSignalIcon from 'camera-module/camera/core/CellularSignalIcon'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '20px',
    width: '10.25rem',
    height: '7.25rem',
    textAlign: 'center',
  },
  icon: {
    width: '50px',
  },
  value: {
    fontSize: '0.813rem',
    fontFamily: theme.typography.fontFamilyBold,
    fontWeight: 'bold',
    marginTop: '10px',
  },
  valueLowSignal: {
    marginTop: '10px',
    color: theme.palette.status?.error,
  },
  label: {
    marginTop: '0px',
    fontSize: theme.typography.caption2?.fontSize,
    color: theme.palette.text.primary,
  },
}))

const CellularSignalTile = ({ value }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const signalText = (() => {
    switch (value.bar) {
      case 0: return t('camera.no_signal')
      case 1: return '1/5'
      case 2: return '2/5'
      case 3: return '3/5'
      case 4: return '4/5'
      case 5: return '5/5'
      default: return t('camera.no_signal')
    }
  })()

  const isValueLowSignal = value.lowSignal || value.bar === 0 || value.bar === 1

  return (
    <div className={classes.root} data-testid="tile">
      <CellularSignalIcon bar={value.bar} />
      <div className={[classes.value, isValueLowSignal ? classes.valueLowSignal : ''].join(' ')}>
        { signalText }
      </div>
      <div className={classes.label}>
        { t('camera.signal') }
      </div>
    </div>
  )
}

export default CellularSignalTile
