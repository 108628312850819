import { useDispatch } from 'react-redux'
import useUser from 'user-module/user/core/useUser'
import { useHistory } from 'react-router-dom'
import cameraActions from 'vosker/src/camera-module/camera/core/cameraActions'

const useCameraActions = () => {
  const dispatch = useDispatch()
  const user = useUser()
  const history = useHistory()

  return {
    cameraMigrationError: (camera) => cameraActions.cameraMigrationError(camera, user, history),
    update: cameraId => dispatch(cameraActions.update(cameraId)),
    updateSettings: (camera, newSettings) => dispatch(cameraActions.updateSettings(camera, newSettings)),
  }
}

export default useCameraActions
