import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import useUser from 'user-module/user/core/useUser'
import ConfirmationStep from 'user-module/account/management/ConfirmationStep'

import VoskerStepper from 'vosker/src/components/VoskerStepper'
import PasswordValidationStep from 'user-module/account/management/PasswordValidationStep'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.contrast,
    marginTop: 24,
    marginBottom: 24,
    borderRadius: '6px',
    padding: '1.5rem 2rem 3rem',
    [theme.breakpoints.down('md')]: {
      padding: '1.5rem 2rem 2rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 1rem 3rem',
    },
  },
  stepsContainer: {
    padding: '12px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
  },
  title: {
    color: theme.palette.text?.tertiary,
    marginBottom: 20,
  },
  inputContainer: {
    maxWidth: '40%',
    marginLeft: 32,
    marginTop: 16,
    [theme.breakpoints.only('sm')]: {
      maxWidth: '60%',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
}))

const RenderCheckbox = ({ onSubmit, onCancel, onReset, disabled, completed, toggleCompletion }) => (
  <ConfirmationStep
    onSubmit={onSubmit}
    onCancel={onCancel}
    onReset={onReset}
    disabled={disabled}
    completed={completed}
    toggleCompletion={toggleCompletion}
  />
)

const RenderInput = ({ onSubmit, disabled }) => {
  return <PasswordValidationStep onSubmit={onSubmit} disabled={disabled} />
}

const ManagementPanel = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useUser()

  const [completed, setIsCompleted] = useState(false)
  const toggleCompletion = () => setIsCompleted(!completed)

  const steps = [
    {
      label: 'app:account.management.stepper.first_step.label',
      description: 'app:account.management.stepper.first_step.description',
      actionLabel: 'app:account.management.stepper.first_step.action_label',
    },
    {
      label: 'app:account.management.stepper.second_step.label',
      description: 'app:account.management.stepper.second_step.description',
      removeStepButton: true,
      actionLabel: t('button.next'),
      renderAction: (disabled, onSubmit) => <RenderInput disabled={disabled} onSubmit={onSubmit} />,
    },
    {
      label: 'app:account.management.stepper.last_step.label',
      description: 'app:account.management.stepper.last_step.description',
      removeStepButton: true,
      renderAction: (disabled,
        onSubmit,
        onCancel,
        onReset) => (
          <RenderCheckbox
            disabled={disabled}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onReset={onReset}
            completed={completed}
            toggleCompletion={toggleCompletion}
          />
      ),
    },
  ]

  return (
    <Box data-testid="accountManagement" className={classes.root}>
      <Typography variant="h2" className={classes.title}>{ t('app:account.management.subtitle') }</Typography>

      <Box className={classes.stepsContainer}>
        <VoskerStepper
          steps={steps}
          expandable
          validateCompletion
          isCompleted={completed}
          disabled={user.isReadOnlyProfile}
          onNext={toggleCompletion}
        />
      </Box>
    </Box>
  )
}

export default ManagementPanel
