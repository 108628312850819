import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Box from '@material-ui/core/Box'

import Head from 'shared-module/new-components/head/Head'
import Title from 'shared-module/new-components/layout/Title'
import ForwardBackLink from 'shared-module/components/FowardBackLink'
import LoginRegisterWrapper from 'user-module/user/ui/LoginRegisterWrapper'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import { makeStyles } from '@material-ui/core/styles'
import RegisterButton from 'user-module/user/ui/register/components/RegisterButton'
import PasswordField from 'storybook-component-module/src/wrappers/fields/PasswordField'
import EmailField from 'storybook-component-module/src/wrappers/fields/EmailField'
import PhoneNumberField from 'storybook-component-module/src/wrappers/fields/PhoneNumberField'
import CountryField from 'storybook-component-module/src/wrappers/fields/CountryField'
import FirstNameField from 'storybook-component-module/src/wrappers/fields/FirstNameField'
import LastNameField from 'storybook-component-module/src/wrappers/fields/LastNameField'
import TermsCheckbox from 'user-module/user/ui/register/components/TermsCheckbox.js'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  link: {
    color: `${theme.palette.status?.info}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
      paddingRight: 0,
      '&:hover': {
        borderColor: theme.palette?.border?.secondary,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      borderColor: theme.palette?.border?.secondary,
      borderWidth: 1.5,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: '0.25rem',
    },
  },
  label: {
    marginTop: 10,
  },
}))

const RegisterPage = ({ isComplexPassword = false }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const classes = useStyles()
  const importedClasses = VoskerInputStyles()
  const [checked, setChecked] = useState(false)
  const consentEnabled = appConfig.consent.switch === switches.on

  return (
    <LoginRegisterWrapper>
      <Head title={t('app:register.head.title')} />
      <Title>{ t('register.title') }</Title>

      <Box component="div" mb={1}>
        { t('register.login.title') }
        <Box ml={1} component="span" display="inline-block">
          <ForwardBackLink id="lnk_login" pathname="/login" underline="none" search={location.search} className={classes.link}>
            { t('register.login_to_my_account') }
          </ForwardBackLink>
        </Box>
      </Box>

      <FormDataContext>
        { consentEnabled && (
          <>
            <FirstNameField
              fieldName="firstName"
              className={clsx(importedClasses.input, classes.input)}
              validations
            />
            <LastNameField
              fieldName="lastName"
              className={clsx(importedClasses.input, classes.input)}
              validations
            />
          </>
        ) }
        <EmailField
          fieldName="email"
          className={clsx(importedClasses.input, classes.input)}
          validations
        />

        <PasswordField
          fieldName="password"
          showHints
          validations
          className={clsx(importedClasses.input, classes.input)}
          isComplexPassword={isComplexPassword}
        />

        <CountryField
          fieldName="country"
          className={clsx(importedClasses.input, classes.input)}
          validations
        />

        <PhoneNumberField
          fieldName="phone"
          className={clsx(importedClasses.input, classes.input)}
          validations
        />
        { consentEnabled && (
          <TermsCheckbox
            checked={checked}
            setChecked={setChecked}
            fieldName="consent"
            validations
          />
        ) }
        <RegisterButton consentEnabled={consentEnabled} checked={checked} />
      </FormDataContext>
    </LoginRegisterWrapper>
  )
}

export default RegisterPage
