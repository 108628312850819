import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import VoskerInputField from 'vosker/src/components/buttons/VoskerInputField'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 16,
    marginLeft: 32,
  },
  spacing: {
    marginBottom: '4rem',
  },
  inputContainer: {
    maxWidth: '40%',
    marginLeft: 32,
    marginTop: 16,
    marginRight: 40,
    [theme.breakpoints.only('sm')]: {
      maxWidth: '60%',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },
}))

const PasswordValidationForm = ({ disabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const formik = useFormikContext()

  return (
    <>
      <VoskerInputField
        required
        margin="none"
        name="password"
        type="password"
        label={t('input.password_current')}
        showErrorIcon
        showSuccessTickIcon
        notShowSuccessColor
        disabled={disabled}
        className={classes.inputContainer}
      />

      <Grid container spacing={0}>
        <Grid item>
          <VoskerPrimaryButton
            variety="primary"
            color="primary"
            type="submit"
            disabled={!(formik.isValid && formik.dirty) || disabled}
            className={clsx(classes.button, classes.spacing)}
          >
            { t('button.next') }
          </VoskerPrimaryButton>
        </Grid>
      </Grid>
    </>
  )
}

export default PasswordValidationForm
