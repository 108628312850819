import { useTranslation } from 'react-i18next'
import ForwardBackLink from 'shared-module/components/FowardBackLink'
import { Box } from '@material-ui/core'
import { Mixpanel } from 'vosker/src/Mixpanel'

const ForgotPasswordLink = () => {
  const { t } = useTranslation()

  const trackEvent = async () => await Mixpanel.track('Forgot Password')

  return (
    <Box display="flex" flexDirection="column" mt={3}>
      <ForwardBackLink id="lnk_password" pathname="/reset-password" align="right" onClick={trackEvent}>{ t('common:login.forgot_password') } </ForwardBackLink>
    </Box>
  )
}

export default ForgotPasswordLink
