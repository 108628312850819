import CameraStatusTile from 'camera-module/camera/ui/camera-status-page/CameraStatusTile'
import { useTranslation } from 'react-i18next'
import TimeSynchronized from 'assets/icons/status/TimeSynchronized'

const TimeSynchronizedTile = ({ value }) => {
  const { t } = useTranslation()

  return value === false
    ? <CameraStatusTile label="camera.time_synchronized.label" icon={<TimeSynchronized />} value={t('camera.time_synchronized.incorrect')} useSVGIcon />
    : null
}

export default TimeSynchronizedTile
