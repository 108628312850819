import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useVoskerPhotoActions from 'video-module/video-clip/core/hd-photo-state/useVoskerPhotoActions'
import downloadStatus from 'photo-module/download/downloadStatus'
import { defaultTrackingProperties, Mixpanel } from 'vosker/src/Mixpanel'
import useUser from 'user-module/user/core/useUser'
import { useCameras } from 'camera-module/cameras/core/useCameras'

const DialogHdPhotoRequest = ({ open, onClose, remainingPhotos }) => {
  const { t } = useTranslation()
  const photo = useSelectedPhoto()
  const user = useUser()
  const camera = useSelectedCamera()
  const cameras = useCameras()
  const actions = useVoskerPhotoActions()

  const acknowledgeRequest = async () => {
    onClose()

    if (photo.hdStatus === downloadStatus.error) {
      await actions.clearHdPhoto(photo.cameraId, photo.id)
    }

    await actions.requestHdPhoto(camera.id, photo.id)

    await Mixpanel.track('HD Confirmed', {
      'Camera Model': camera.config.model,
      'Camera Plan': camera.subscription.plan.name,
      'HD Photos Left': remainingPhotos - 1,
      'Media Type': 'PHOTO',
      ...defaultTrackingProperties(user, cameras),
    })
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('photo_viewer.request_full_hd_photo.title')}
      text={t('photo_viewer.request_full_hd_photo.text', {
        remaining: camera?.subscription.plan.hdCountPerMonth - camera?.subscription.hdPhotoCount,
      })}
      confirmText={t('photo_viewer.request_full_hd_photo.confirm')}
      onConfirm={acknowledgeRequest}
      onCancel={onClose}
    />
  )
}

export default DialogHdPhotoRequest
