import { useEffect, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import DialogBox from 'shared-module/components/DialogBox'
import SettingsSchedule from './SettingsSchedule'
import Grid from '@material-ui/core/Grid'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import { makeStyles } from '@material-ui/core/styles'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const useStyles = makeStyles(() => ({
  styleDialogPadding: {
    '& .MuiDialog-paper': {
      padding: '0px !important',
    },
  },
}))

const ScheduleField = ({ name }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()

  const [field] = useField(name)
  const { setFieldValue, setTouched } = useFormikContext()
  const dialogActions = useConfirmationDialogActions()

  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')

  const onOpen = () => {
    if (camera.status.capability.instantMode && camera.config.detectionSchedule) {
      if (!camera.config.schedule) {
        dialogActions.open()
      } else {
        setOpen(true)
      }
    } else setOpen(true)
  }

  const onClose = () => setOpen(false)
  const closeDialog = () => dialogActions.close()

  useEffect(() => {
    setValue(field.value)
  }, [field.value])

  const onChange = event => {
    const updatedValue = event.target.value
    setValue(updatedValue)
    setFieldValue(name, updatedValue, false)
    setTouched({ name: true })
  }

  return (
    <div>
      <Grid display="flex" flexdirection="row" justifyContent="flex-end" container spacing={1}>
        <Grid item xs={6} sm={3}>
          <VoskerSecondaryButton onClick={onOpen} color="primary" variant="outlined">
            { t('camera.settings.buttons.edit') }
          </VoskerSecondaryButton>
        </Grid>
      </Grid>

      <DialogBox title={t('app:camera.settings.fields.schedule.label')} open={open} onClose={onClose} className={classes.styleDialogPadding}>
        <SettingsSchedule settings={{ [name]: value }} onChange={onChange} />
      </DialogBox>

      <ConfirmationDialog
        open={dialogActions.isOpen}
        onClose={closeDialog}
        onCancel={closeDialog}
        title={t('app:camera.new_schedule_dialog.title')}
        text={t('app:camera.new_schedule_dialog.text')}
        cancelText={t('app:camera.new_schedule_dialog.cancel')}
      />
    </div>
  )
}

export default ScheduleField
