import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FullHdVideoIcon from 'assets/icons/video/FullHdVideoIcon'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import DialogFullHdVideoRequest from 'video-module/video-clip/ui/full-hd-video-status-button/dialog/DialogFullHdVideoRequest'
import DialogFullHdVideoCountError from 'video-module/video-clip/ui/full-hd-video-status-button/dialog/DialogFullHdVideoCountError'
import DialogFullHdVideoRequestElite from 'video-module/video-clip/ui/full-hd-video-status-button/dialog/DialogFullHdVideoRequestElite'
import VoskerOutlinedButton from 'vosker/src/components/buttons/VoskerOutlinedButton'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { defaultTrackingProperties, Mixpanel } from 'vosker/src/Mixpanel'
import useUser from 'user-module/user/core/useUser'
import { useLoadCameras } from 'camera-module/cameras/core/useCameras'

const useStyles = makeStyles(theme => ({
  button: {
    height: '2.5rem',
    whiteSpace: 'nowrap',
    borderColor: theme.palette.basic?.lightGrey + ' !important',
    '&:hover': {
      background: alpha(theme.palette.basic?.dark, 0.75) + ' !important',
    },
  },
  icon: {
    height: '1.25rem !important',
  },
}))

const FullHdVideoRequestableButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useUser()
  const camera = useSelectedCamera()
  const cameras = useLoadCameras()
  const videoRequestElite = useConfirmationDialogActions()
  const videoRequestDialogActions = useConfirmationDialogActions()
  const videoVideoCountErrorDialogActions = useConfirmationDialogActions()
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const remainingVideos = camera?.subscription.videoLimit - camera?.subscription.videoCount

  const isElitePlan = () => {
    camera?.subscription?.plan?.name === apiCameraPlans.elite
      ? videoRequestElite.open()
      : videoVideoCountErrorDialogActions.open()
  }

  const openRequestDialog = async () => {
    videoRequestDialogActions.open()

    await Mixpanel.track('HD Request', {
      'Camera Model': camera.config.model,
      'Camera Plan': camera.subscription.plan.name,
      'HD Videos Left': remainingVideos,
      'Media Type': 'PHOTO',
      ...defaultTrackingProperties(user, cameras),
    })
  }

  const handleClick = () => {
    camera.subscription.videoCount < camera.subscription.videoLimit
      ? openRequestDialog()
      : isElitePlan()
  }

  return (
    <>
      <VoskerOutlinedButton
        className={classes.button}
        onClick={handleClick}
        icon={<FullHdVideoIcon className={classes.icon} />}
      >
        { smallScreen
          ? t('photo_viewer.request_full_hd_video.short_button')
          : t('photo_viewer.request_full_hd_video.button') }
      </VoskerOutlinedButton>

      <DialogFullHdVideoRequest open={videoRequestDialogActions.isOpen} onClose={videoRequestDialogActions.close} remainingVideos={remainingVideos} />
      <DialogFullHdVideoRequestElite open={videoRequestElite.isOpen} onClose={videoRequestElite.close} />
      <DialogFullHdVideoCountError
        open={videoVideoCountErrorDialogActions.isOpen}
        onClose={videoVideoCountErrorDialogActions.close}
      />
    </>
  )
}

export default FullHdVideoRequestableButton
