import CoordinatesTile from 'camera-module/camera/ui/camera-status-page/tiles/CoordinatesTile'
import LastSyncTile from 'camera-module/camera/ui/camera-status-page/tiles/LastSyncTile'
import MemoryTile from 'camera-module/camera/ui/camera-status-page/tiles/MemoryTile'
import CameraTile from 'camera-module/camera/ui/camera-status-page/tiles/CameraTile'
import { FirmwareTile } from 'camera-module/camera/ui/camera-status-page/tiles/ModemFirmwareTile'
import CellularSignalTile from 'camera-module/camera/ui/camera-status-page/tiles/CellularSignalTile'
import TemperatureTile from 'camera-module/camera/ui/camera-status-page/tiles/TemperatureTile'
import MainBatteryTile from 'camera-module/camera/ui/camera-status-page/tiles/MainBatteryTile'
import SolarBatteryTile from 'camera-module/camera/ui/camera-status-page/tiles/SolarBatteryTile'
import ActivationCodeTile from 'camera-module/camera/ui/camera-status-page/tiles/ActivationCodeTile'
import TimeSynchronizedTile from 'camera-module/camera/ui/camera-status-page/tiles/TimeSynchronizedTile'
import VoskerFirstBatteryTile from './tiles/VoskerFirstBatteryTile'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

let tilesMap
const useStyles = makeStyles(() => ({
  centered: {
    margin: '10px 0 10px 0',
  },
}))

const CameraStatusTiles = ({ cameraStatus, cameraConfig, firmwareUpdate, requested, sendCommands, commandName }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const configEnabled = configName => {
    switch (configName) {
      case 'coordinates' :
        return cameraConfig.gps !== false
      default:
        return true
    }
  }
  if (cameraStatus.powerSources?.length > 0) {
    tilesMap = {
      model: (props) => <CameraTile {...props} />,
      lastUpdate: (props) => <LastSyncTile {...props} />,
      firstBattery: VoskerFirstBatteryTile,
      solarBattery: (props) => <SolarBatteryTile {...props} />,
      signal: (props) => <CellularSignalTile {...props} />,
      temperature: (props) => <TemperatureTile {...props} />,
      memory: (props) => <MemoryTile used={cameraStatus.memory.used} size={cameraStatus.memory.size} requested={requested} sendCommands={sendCommands} commandName={commandName} {...props} />,
      version: (props) => <FirmwareTile firmwareUpdate={firmwareUpdate} requested={requested} sendCommands={sendCommands} commandName={commandName} {...props} />,
      activationCode: (props) => <ActivationCodeTile {...props} />,
      coordinates: (props) => <CoordinatesTile {...props} />,
      timeSynchronized: (props) => <TimeSynchronizedTile {...props} />,
    }
  } else {
    tilesMap = {
      model: (props) => <CameraTile {...props} />,
      lastUpdate: (props) => <LastSyncTile {...props} />,
      mainBattery: MainBatteryTile,
      solarBattery: (props) => <SolarBatteryTile {...props} />,
      signal: (props) => <CellularSignalTile {...props} />,
      temperature: (props) => <TemperatureTile {...props} />,
      memory: (props) => <MemoryTile used={cameraStatus.memory.used} size={cameraStatus.memory.size} {...props} />,
      version: (props) => <FirmwareTile firmwareUpdate={firmwareUpdate} {...props} />,
      activationCode: (props) => <ActivationCodeTile {...props} />,
      coordinates: (props) => <CoordinatesTile {...props} />,
      timeSynchronized: (props) => <TimeSynchronizedTile {...props} />,
    }
  }

  return Object.keys(tilesMap)
    .filter(key => cameraStatus[key] !== undefined)
    .filter(configEnabled)
    .map((key) => {
      const Tile = tilesMap[key]
      if (cameraStatus.powerSources?.length > 0 && key === 'solarBattery') {
        return cameraStatus.powerSources.map((powerSource, index) => (
          <Grid item key={`${key}-${index}`} className={classes.centered}>
            <VoskerFirstBatteryTile
              key={`${key}-${index}`}
              type={powerSource.type}
              label={t('app:camera.battery_vkx', { type: powerSource.type })}
              value={powerSource.percentage}
            />
          </Grid>
        ))
      }
      if (cameraStatus.batteries?.length > 0 && key === 'mainBattery') {
        const label = `${t('app:camera.battery_vkx')} ${cameraStatus.batteryType}`
        return (
          <Grid item key={key} className={classes.centered}>
            <Tile key={key} value={cameraStatus[key]} label={label} activePowerSource={cameraStatus.activePowerSource} />
          </Grid>
        )
      }
      return (
        <Grid item key={key} className={classes.centered}>
          <Tile key={key} value={cameraStatus[key]} />
        </Grid>
      )
    })
}

export default CameraStatusTiles
