import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import FullHdVideoCrossedOutIcon from 'assets/icons/video/FullHdVideoCrossedOutIcon'
import VoskerOutlinedButton from 'vosker/src/components/buttons/VoskerOutlinedButton'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import DialogFullHdVideoUnavailable from 'video-module/video-clip/ui/full-hd-video-status-button/dialog/DialogFullHdVideoUnavailable'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  button: {
    height: '2.5rem',
    whiteSpace: 'nowrap',
    borderColor: alpha(theme.palette.border?.light, 0.3) + ' !important',
    '&:hover': {
      background: alpha(theme.palette.basic?.dark, 0.75) + ' !important',
    },
  },
  icon: {
    height: '1.25rem !important',
  },
}))

const FullHdVideoUnavailableButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dialogActions = useConfirmationDialogActions()
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <VoskerOutlinedButton
        className={classes.button}
        onClick={dialogActions.open}
        icon={<FullHdVideoCrossedOutIcon className={classes.icon} />}
      >
        { smallScreen
          ? t('photo_viewer.full_hd_video.short_unavailable_button')
          : t('photo_viewer.full_hd_video.unavailable_button') }
      </VoskerOutlinedButton>

      <DialogFullHdVideoUnavailable open={dialogActions.isOpen} onClose={dialogActions.close} />
    </>
  )
}

export default FullHdVideoUnavailableButton
