import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import DialogHdPhotoUnavailable from 'vosker/src/photos-module/photo/ui/photo-viewer/hd-photo-status-button/dialog/DialogHdPhotoUnavailable'
import VoskerOutlinedButton from 'vosker/src/components/buttons/VoskerOutlinedButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { useTranslation } from 'react-i18next'
import FullHdVideoCrossedOutIcon from 'assets/icons/video/FullHdVideoCrossedOutIcon'

const useStyles = makeStyles(theme => ({
  button: {
    height: '2.5rem',
    whiteSpace: 'nowrap',
    borderColor: alpha(theme.palette.border?.light, 0.3) + ' !important',
    '&:hover': {
      background: alpha(theme.palette.basic?.dark, 0.75) + ' !important',
    },
  },
  icon: {
    height: '1.25rem !important',
  },
}))

const HdPhotoUnavailableButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dialogActions = useConfirmationDialogActions()

  return (
    <>
      <VoskerOutlinedButton
        className={classes.button}
        onClick={dialogActions.open}
        icon={<FullHdVideoCrossedOutIcon className={classes.icon} />}
      >
        { t('photo_viewer.hd_photo.unavailable_button') }
      </VoskerOutlinedButton>

      <DialogHdPhotoUnavailable open={dialogActions.isOpen} onClose={dialogActions.close} />
    </>
  )
}

export default HdPhotoUnavailableButton
