import { Formik } from 'formik'
import RouteLeavingGuard from 'shared-module/RouteLeavingGuard'

const Form = ({
  data,
  onSubmit,
  validationSchema,
  validate,
  children,
  leaveGuard = false,
  leaveGuardTexts,
  validateOnBlur = true,
  validateOnChange = true,
  ...formProps

}) => {
  const onFormikSubmit = async (formData, form) => {
    await onSubmit(formData, form)
    form.setSubmitting(false)
    form.setTouched({})
  }
  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      validate={validate}
      enableReinitialize
      onSubmit={onFormikSubmit}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
    >
      { ({ handleSubmit, handleReset, touched }) => (
        <>
          { leaveGuard && !!leaveGuardTexts && (
            <RouteLeavingGuard
              when={Object.keys(touched).length > 0}
              title={leaveGuardTexts.title}
              message={leaveGuardTexts.content}
            />
          ) }
          <form
            noValidate
            {...formProps}
            onSubmit={handleSubmit}
            onReset={handleReset}
          >
            { children }
          </form>
        </>
      ) }
    </Formik>
  )
}

export default Form
