import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MuiBox from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import CInput from '../../components/inputs/CInput'
import PasswordHint from 'vosker/src/form-module/ui/PasswordHint'
import ForwardBackLink from 'shared-module/components/FowardBackLink'
import { useFormField } from 'shared-module/new-components/form/FormDataContext'
import { isPasswordValid } from 'vosker/src/form-module/core/passwordValidation'

export interface IPasswordField {
  fieldName: string
  label?: string
  validations?: boolean
  disabled?: boolean
  className?: string
  margin?: 'dense' | 'normal' | 'none'
  showLink?: boolean
  showHints?: boolean
  hideEyeIcon?: boolean
  validationSchema?: Object
  isComplexPassword?: boolean
}

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  },
  input: {
    marginBottom: 6,
  },
  hint: {
    paddingLeft: 4,
    fontSize: 12,
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
  },
  disabled: {
    pointerEvents: 'none',
    color: theme.palette.text.disabled,
  },
}))

// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

const PasswordField = (props: IPasswordField) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const field = useFormField(props.fieldName)

  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  const passwordValidations = {
    required: t('input.password_required'),
    minLength: { value: 10, message: t('form.password-hint.simple-validation') },
  }

  const complexPasswordValidations = {
    required: t('input.password_required'),
    validate: password => isPasswordValid(password || '') ? true : t('app:form.validation.invalid-password'),
  }

  const inputProperties = {
    endAdornment: !props.hideEyeIcon && (
      <InputAdornment position="end">
        <IconButton color="secondary" onClick={toggleShowPassword} disabled={props.disabled}>
          { showPassword ? <Visibility fontSize="small" className={props.className} /> : <VisibilityOff fontSize="small" className={props.className} /> }
        </IconButton>
      </InputAdornment>
    ),
  }

  const fieldRegistration = props.validations
    ? props.validationSchema
      ? field.inputRef(props.validationSchema)
      : field.inputRef(props.isComplexPassword ? complexPasswordValidations : passwordValidations)
    : field.inputRef

  return (
    <Box display="flex" flexDirection="column" className={props.className}>
      <CInput
        name={field.name}
        type={showPassword ? 'text' : 'password'}
        label={props.label ?? t('common:login.form.password')}
        margin={props.margin}
        disabled={props.disabled}
        className={props.className}
        inputProps={inputProperties}
        register={fieldRegistration}
        onChange={field.onChange}
        error={field.error}
        showHints={props.showHints}
        hints={props.showHints && props.isComplexPassword
          ? <PasswordHint name="password" />
          : <Typography className={classes.hint}>{ t('form.password-hint.simple-validation') }</Typography>}
      />

      { props.showLink && (
        <Box display="flex" flexDirection="column" mt={1.5} mb={1}>
          <ForwardBackLink
            pathname="/reset-password"
            align="right"
            className={clsx(classes.text, props.disabled ? classes.disabled : null)}
          >

            { t('common:login.forgot_password') }
          </ForwardBackLink>
        </Box>
      ) }
    </Box>
  )
}

export default PasswordField
