import PublicPageLayout from 'shared-module/new-components/public-page/PublicPageLayout'
import VoskerPublicPageBanner from 'vosker/src/shared-module/ui/public-page/VoskerPublicPageBanner'

const VoskerPublicPageLayout = ({ children }) => {
  return (
    <PublicPageLayout Banner={VoskerPublicPageBanner}>{ children }</PublicPageLayout>
  )
}

export default VoskerPublicPageLayout
