import React from 'react'
import { useTranslation } from 'react-i18next'

import CInput from '../../components/inputs/CInput'
import { useFormField } from 'shared-module/new-components/form/FormDataContext'

export interface IEmailField {
  fieldName: string
  label?: string
  defaultValue?: string
  validations?: boolean
  validationSchema?: Object
  className?: string
  disabled?: boolean
  margin?: 'dense' | 'normal' | 'none'
}

const EMAIL_VALIDATION_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const EmailField = (props: IEmailField) => {
  const { t } = useTranslation()
  const field = useFormField(props.fieldName)

  const defaultValidation = {
    required: t('input.email_required'),
    validate: email => EMAIL_VALIDATION_REGEX.test(email) || t('input.email_invalid'),
  }

  const fieldRegistration = props.validations
    ? props.validationSchema
      ? field.inputRef(props.validationSchema)
      : field.inputRef(defaultValidation)
    : field.inputRef

  return (
    <CInput
      name={field.name}
      type="text"
      className={props.className}
      defaultValue={props.defaultValue}
      label={props.label || t('common:login.form.email')}
      margin={props.margin}
      register={fieldRegistration}
      onChange={field.onChange}
      error={field.error}
      disabled={props.disabled}
    />
  )
}

export default EmailField
