import PhotoTile from 'photo-module/photos/ui/photos-gallery/PhotoTile'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SelectPhoto from 'photo-module/photos/ui/photos-gallery/SelectPhoto'
import usePhotosDelete from 'photo-module/photos/ui/photos-gallery/usePhotosDelete'
import downloadStatus from 'photo-module/download/downloadStatus'
import { CheckEveryItemsAreIncluded, RanderDatesDay } from './GalleryHelperFunctions'

const useStyles = makeStyles(() => ({
  photos: {
    display: 'flex',
    flexFlow: 'wrap',
  },
  date: {
    fontWeight: 'bold',
    fontSize: '10pt',
    marginTop: '25px',
    marginBottom: '8px',
    marginLeft: '12px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dateText: ({ multiSelectEnabled }) => ({
    textTransform: 'capitalize',
    marginLeft: multiSelectEnabled ? '-8px' : '-5px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    '& .MuiFormControlLabel-root': {
      margin: '0px',
    },
  }),
}))

const PhotosDay = ({ photosList, date, PhotoOverlay, setIsIntersecting }) => {
  const { photosToDeleteActions, photoIdsToDelete, multiSelectEnabled } = usePhotosDelete()
  const classes = useStyles({ multiSelectEnabled })

  let photoTiles = []
  const filterOutPendingPhotos = photosList.filter(photo => photo.hdStatus !== downloadStatus.pending && photo.sdVideoStatus !== downloadStatus.pending && photo.hdVideoStatus !== downloadStatus.pending)
  const filterOutPendingPhotosPerDay = filterOutPendingPhotos.map(item => item.id)

  photosList.forEach(photo => {
    photoTiles = [...photoTiles, <PhotoTile
      key={photo.id}
      photo={photo}
      size="medium"
      PhotoOverlay={PhotoOverlay}
      setIsIntersecting={setIsIntersecting}
    />]
  })

  const onClickHandler = () => {
    CheckEveryItemsAreIncluded(filterOutPendingPhotosPerDay, photoIdsToDelete) ? photosToDeleteActions.delete(filterOutPendingPhotosPerDay) : photosToDeleteActions.add(filterOutPendingPhotosPerDay)
  }

  return (
    <>
      <div className={classes.date} onClick={onClickHandler}>
        { multiSelectEnabled && (
          <SelectPhoto selectorPerDay allShouldBeChecked={CheckEveryItemsAreIncluded(filterOutPendingPhotosPerDay, photoIdsToDelete)} />
        ) }
        <div className={classes.dateText}>{ RanderDatesDay(date) }</div>
      </div>
      <div className={classes.photos}>
        { photoTiles }
      </div>
    </>
  )
}

export default PhotosDay
