import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import detectionActions from './detectionZone.actions'
import { anchorListsAreEqual } from 'detection-zone-module/core/detectionZone.selectors'
import cameraActions from 'vosker/src/camera-module/camera/core/cameraActions'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const useDetectionZone = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const camera = useSelectedCamera()
  const state = useSelector((state) => state.detectionZone)

  useEffect(() => {
    if (!camera) {
      dispatch(cameraActions.get(id))
    } else if (!state.anchorList || state.anchorList.length === 0 || !anchorListsAreEqual(camera.config.detectionZone.anchors, state.anchorList)) {
      dispatch(detectionActions.setAnchorList(camera.config.detectionZone.anchors))
      dispatch(detectionActions.setInitialAnchorList(camera.config.detectionZone.anchors))
      dispatch(detectionActions.setIsDefaultZone(camera.config.detectionZone.isDefaultZone))
      dispatch(detectionActions.setZoneName(camera.config.detectionZone.name))
      dispatch(detectionActions.setInitialZoneName(camera.config.detectionZone.name))
      dispatch(detectionActions.setLastSavedZoneName(camera.config.detectionZone.name))
      camera.config.detectionZone.isDefaultZone && dispatch(detectionActions.setIsEditing(true))
    }

    if (camera && camera.config.detectionZone.isEnabled !== state.isEnabled) {
      dispatch(detectionActions.setIsEnabled(camera.config.detectionZone.isEnabled))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera])

  const saveZoneName = (zoneName) => {
    dispatch(detectionActions.setZoneName(zoneName))
    dispatch(detectionActions.setLastSavedZoneName(zoneName))
  }

  return {
    isEditing: state.isEditing,
    anchorList: state.anchorList,
    initialAnchorList: state.initialAnchors,
    isDefaultZone: state.isDefaultZone,
    isEnabled: state.isEnabled,
    initialZoneName: state.initialZoneName,
    zoneName: state.zoneName,
    lastSavedZoneName: state.lastSavedZoneName,
    setIsEditing: isEditing => dispatch(detectionActions.setIsEditing(isEditing)),
    setIsEnabled: isEnabled => dispatch(detectionActions.setIsEnabled(isEnabled)),
    setIsDefaultZone: isDefaultZone => dispatch(detectionActions.setIsDefaultZone(isDefaultZone)),
    setInitialAnchorList: initialAnchors => dispatch(detectionActions.setInitialAnchorList(initialAnchors)),
    saveAnchorList: anchorList => dispatch(detectionActions.setAnchorList(anchorList)),
    setZoneName: zoneName => dispatch(detectionActions.setZoneName(zoneName)),
    setLastSavedZoneName: zoneName => dispatch(detectionActions.setLastSavedZoneName(zoneName)),
    setInitialZoneName: initialZoneName => dispatch(detectionActions.setInitialZoneName(initialZoneName)),
    resetAnchorList: () => dispatch(detectionActions.setAnchorList([])),
    saveZoneName,
  }
}

export default useDetectionZone
