import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useDetectionZone from 'detection-zone-module/core/detectionZone.hooks'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import Grid from '@material-ui/core/Grid'

const DetectionField = ({ dialogActions }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const camera = useSelectedCamera()
  const { isDefaultZone } = useDetectionZone()
  const { photos } = useSelector(state => state.galleryPhoto)

  const detectionZoneAction = () => photos.length === 0 ? dialogActions.open() : history.push(`/camera/${camera.id}/detection-zone`)

  return (
    <Grid display="flex" flexdirection="row" justifyContent="flex-end" container spacing={1}>
      <Grid items xs={6} sm={3}>
        <VoskerSecondaryButton color="primary" variety="secondary" onClick={detectionZoneAction}>
          { isDefaultZone ? t('camera.settings.buttons.create') : t('camera.settings.buttons.edit') }
        </VoskerSecondaryButton>
      </Grid>
    </Grid>
  )
}

export default DetectionField
