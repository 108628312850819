// noinspection JSValidateTypes

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import isBetweenPlugin from 'dayjs/plugin/isBetween'

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import Form from 'form-module/ui'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { defaultTrackingProperties, Mixpanel } from 'vosker/src/Mixpanel'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import TimePeriodForm from 'vosker/src/photos-module/photos-gallery/ui/filters-bar/TimePeriodForm'
import CustomPickerWrapper from 'vosker/src/photos-module/photos-gallery/ui/filters-bar/CustomPickerWrapper'
import CustomCalendarHeader from 'vosker/src/photos-module/photos-gallery/ui/filters-bar/CustomCalendarHeader'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import { localization } from 'vosker/src/photos-module/photos-gallery/ui/filters-bar/utils/CustomMonthsShortKeys'
import useUser from 'user-module/user/core/useUser'
import { useCameras } from 'camera-module/cameras/core/useCameras'

dayjs.extend(isBetweenPlugin)
require('dayjs/locale/de')
require('dayjs/locale/fr')
require('dayjs/locale/es')
require('dayjs/locale/en')

const useStyles = makeStyles(theme => ({
  customDateCalendar: {
    width: 'max-content !important',
    height: 'max-content !important',
    maxHeight: '320px !important',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '375px !important',
    },
    '& .MuiDayCalendar-weekDayLabel': {
      fontFamily: theme.typography.fontFamily + '!important',
      color: theme.palette.text.primary + '!important',
    },
    '& .MuiDayCalendar-monthContainer': {
      minHeight: 300,
    },
    '& .MuiPickersSlideTransition-root': {
      '&.MuiDayCalendar-slideTransition': {
        minHeight: 300,
        overflow: 'hidden !important',
      },
    },
  },
  buttonContainer: {
    margin: 16,
    paddingTop: 8,
    borderTop: `1px solid ${theme.palette.border?.primary}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      margin: '8px 0 12px',
      borderTop: 'none',
      flexDirection: 'row',
    },
  },
  clearButton: {
    height: '32px !important',
    textTransform: 'capitalize',
    backgroundColor: 'transparent',
    color: theme.palette.status?.info,
    fontSize: theme.typography.label?.fontSize,
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    '&:hover': {
      backgroundColor: theme.palette.background?.empty,
    },
    '& .MuiTouchRipple-root span': {
      backgroundColor: theme.palette.secondary?.light + 'CC',
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    [theme.breakpoints.down('xs')]: {
      textTransform: 'uppercase',
      color: theme.palette.text?.primary,
      backgroundColor: theme.palette.secondary?.main,
      fontSize: theme.typography.button?.fontSize,
      fontFamily: theme.typography.button?.fontFamily,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.secondary?.main + 'CC',
        '&:hover': {
          backgroundColor: theme.palette.secondary?.main + 'CC !important',
        },
      },
    },
  },
  applyButton: {
    minHeight: '32px !important',
    height: '32px !important',
  },
}))

const TimePeriodFilter = ({ setTimePeriodLabel, onClose }) => {
  const { i18n, t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const user = useUser()
  const cameras = useCameras()
  const camera = useSelectedCamera()
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))
  const { hasTimePeriod, timePeriodValues, setTimePeriod, clearTimePeriod } = useFilterCollection()

  const currentDate = dayjs()
  const date = new Date()
  const historyAvailable = camera.subscription?.plan?.historyDays[camera.status?.model]
  const minValueByHistory = historyAvailable === 0 ? null : dayjs(date.setDate(date.getDate() - historyAvailable))

  const [previousValue, setPreviousValue] = useState(null)
  const [value, setValue] = useState(null)
  const [minValue, setMinValue] = useState(minValueByHistory)
  const [hoveredDay, setHoveredDay] = useState(null)

  const [isFirstSelection, setIsFirstSelection] = useState(true)
  const [isSecondSelection, setIsSecondSelection] = useState(false)
  const [selectionCompleted, setSelectionCompleted] = useState(false)

  const [startDateValue, setStartDateValue] = useState(timePeriodValues[0] || '')
  const [endDateValue, setEndDateValue] = useState(timePeriodValues[1] || '')
  const [startDateFocusState, setStartDateFocusState] = useState(true)
  const [endDateFocusState, setEndDateFocusState] = useState(false)

  useEffect(() => {
    if (hasTimePeriod) {
      setPreviousValue(dayjs(timePeriodValues[0]))
      setValue(dayjs(timePeriodValues[1]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetFields = () => {
    setValue(null)
    setStartDateValue('')
    setEndDateValue('')
    setIsFirstSelection(true)
    setIsSecondSelection(false)
    setStartDateFocusState(true)
    setEndDateFocusState(false)
    setMinValue(minValueByHistory)
    setSelectionCompleted(false)
    setTimePeriodLabel('')
    clearTimePeriod()
  }

  const handleChange = e => {
    setValue(e)
    let date = e.$d.toString().substring(3).split(':')[0].slice(0, -2)
    date = [date.slice(0, 7), ',', date.slice(7)].join('')

    if (isFirstSelection || selectionCompleted) {
      setPreviousValue(e)
      setStartDateValue(date)
      setEndDateValue(date)
      setMinValue(e)

      setIsFirstSelection(false)
      setStartDateFocusState(false)
      setEndDateFocusState(true)
      setIsSecondSelection(true)
      setSelectionCompleted(false)
    } else if (isSecondSelection) {
      setEndDateValue(date)
      setMinValue(minValueByHistory)

      setIsFirstSelection(false)
      setStartDateFocusState(true)
      setEndDateFocusState(false)
      setIsSecondSelection(false)
      setSelectionCompleted(true)
    }
  }

  const getFormattedDates = () => {
    const startMonthLabel = localization[i18n.language].montShort[dayjs(startDateValue).$M]
    const endMonthLabel = localization[i18n.language].montShort[dayjs(endDateValue).$M]
    const sDate = startDateValue.replace(/^.{4}/g, startMonthLabel)
    const eDate = endDateValue.replace(/^.{4}/g, endMonthLabel)

    return { sDate, eDate }
  }

  const formattedStartDate = getFormattedDates().sDate
  const formattedEndDate = getFormattedDates().eDate

  const getFormattedLabelFilter = () => {
    const formattedDates = getFormattedDates()

    if (startDateValue === endDateValue) {
      return formattedDates.sDate
    } else if (startDateValue.replace(/(.*),/, '') === endDateValue.replace(/(.*),/, '')) {
      return formattedDates.sDate.substring(0, formattedDates.sDate.indexOf(',')) + ' - ' + formattedDates.eDate
    } else {
      return formattedDates.sDate + ' - ' + formattedDates.eDate
    }
  }

  const initialValues = () => ({
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  })

  const handleSubmit = async () => {
    await Mixpanel.track('Gallery Events Filter Search By Day', {
      'How Many Days Ago': currentDate.diff(dayjs(startDateValue), 'day'),
      ...defaultTrackingProperties(user, cameras),
    })
    setTimePeriodLabel(getFormattedLabelFilter)

    setTimePeriod(true, [startDateValue, endDateValue])
    onClose()
  }

  useEffect(() => {
    setTimePeriodLabel(hasTimePeriod ? getFormattedLabelFilter : '')
    if (!hasTimePeriod) { resetFields() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTimePeriod])

  return (
    <Form
      id="timePeriod"
      data={{ ...initialValues() }}
    >
      <Grid container justifyContent="center" className={classes.container}>
        <TimePeriodForm
          form="timePeriod"
          startDateValue={startDateValue}
          startDateFocusState={startDateFocusState}
          endDateValue={endDateValue}
          endDateFocusState={endDateFocusState}
          formattedStartDate={formattedStartDate}
          formattedEndDate={formattedEndDate}
        />

        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <DateCalendar
              id="DateCalendar"
              value={value}
              minDate={minValue}
              maxDate={currentDate}
              onChange={handleChange}
              className={classes.customDateCalendar}
              slots={{ calendarHeader: CustomCalendarHeader, day: CustomPickerWrapper }}
              slotProps={{
                calendarHeader: () => ({ minDate: minValue }),
                day: (ownerState) => ({
                  selectedDay: value,
                  previousSelectedDay: previousValue,
                  currentDate: currentDate,
                  hoveredDay,
                  onPointerEnter: () => setHoveredDay(ownerState.day),
                  onPointerLeave: () => setHoveredDay(null),
                }),
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item container className={classes.buttonContainer}>
          <Box component={Grid} item xs="auto">
            <VoskerSecondaryButton
              disabled={startDateValue === '' || endDateValue === ''}
              onClick={resetFields}
              className={classes.clearButton}
            >
              { t('app:filters.buttons.clear') }
            </VoskerSecondaryButton>
          </Box>

          <Box component={Grid} ml={1.5} mr={breakpoint && 1.5} item xs="auto">
            <VoskerPrimaryButton
              disabled={startDateValue === '' || endDateValue === ''}
              onClick={handleSubmit}
              className={classes.applyButton}
            >
              { t('app:filters.buttons.apply') }
            </VoskerPrimaryButton>
          </Box>
        </Grid>
      </Grid>
    </Form>
  )
}

export default TimePeriodFilter
