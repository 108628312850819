
import cameraModelsState from 'camera-module/model/core/cameraModelsState'
import cameraPurposesState from './cameraPurposesState'
import { FETCH_CAMERA_PURPOSES, SET_CAMERA_PURPOSES } from './cameraPurposesActionTypes'

const cameraPurposesReducer = (state = cameraPurposesState.init(), action) => {
  switch (action.type) {
    case FETCH_CAMERA_PURPOSES:
      return cameraModelsState.fetching()
    case SET_CAMERA_PURPOSES:
      return cameraPurposesState.set(action.purposes)
    default:
      return state
  }
}

export default cameraPurposesReducer
