import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const DialogFullHdVideoUnavailable = ({ open, onClose }) => {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('photo_viewer.full_hd_video.unavailable.title')}
      text={t('photo_viewer.full_hd_video.unavailable.text')}
      cancelText={t('photo_viewer.full_hd_video.unavailable.confirm')}
      onCancel={onClose}
    />
  )
}

export default DialogFullHdVideoUnavailable
