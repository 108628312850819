import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl'
import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import { FORBIDDEN } from 'shared-module/api/httpStatusCode'
import useLanguage from 'shared-module/components/language/useLanguage'
import ForwardBackLink from 'shared-module/components/FowardBackLink'
import useUserActions from 'user-module/user/core/useUserActions'
import messageActions from 'shared-module/message/messageActions'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import { Mixpanel } from 'vosker/src/Mixpanel'

const RegisterButton = ({ checked, consentEnabled }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const language = useLanguage()
  const history = useHistory()
  const location = useLocation()
  const form = useFormData()
  const userActions = useUserActions()

  const buildApiUser = data => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password,
    cell: data.phone?.replace(/\D+/g, ''),
    country: data.country,
    language: language,
    consent: consentEnabled ? checked : undefined,
  })
  const register = (data) => {
    const user = buildApiUser(data)
    userActions.register(user)
      .then(async res => {
        const { from } = location.state || { from: { pathname: '/' } }
        history.replace(from)
        dispatch(messageActions.showMessage(t('register.success')))
        await Mixpanel.identify(res.user.id)
        await Mixpanel.track('Create Account', { Country: data.country })
      })
      .catch(async error => {
        if (error.response.status === FORBIDDEN) {
          form.setError([{
            name: 'email',
            message: error.response.data.message.includes('deleted')
              ? t('register.email_is_deleted')
              : <>{ t('register.email_already_exist') }<ForwardBackLink underline="always" color="error" pathname="/login">{ t('register.please_login') }</ForwardBackLink></>,
          }])

          !error.response.data.message.includes('deleted') && await Mixpanel.track('Create Account Email Used', { Email: data.email })
        }
      })
  }

  return (
    <FormControl margin="dense">
      <VoskerPrimaryButton
        id="btn_signup"
        color="primary"
        variety="primary"
        type="submit"
        onClick={form.handleSubmit(register)}
        disabled={form.formState.isSubmitting || (!checked && consentEnabled)}
      >
        { t('register.form.button') }
      </VoskerPrimaryButton>
    </FormControl>
  )
}

export default RegisterButton
