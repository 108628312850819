import { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import NotificationsList from './NotificationsList'
import { useNbUnreadNotifications } from 'notifications-module/core/notifications.hooks'

const NotificationsCenter = ({ notificationIcon, notificationIconActive }) => {
  const [open, setOpen] = useState(false)
  const nbUnreadNotifications = useNbUnreadNotifications()

  const onClick = () => setOpen(wasOpen => !wasOpen)
  const close = () => setOpen(false)

  const Icon = nbUnreadNotifications > 0 ? notificationIconActive : notificationIcon

  return (
    <>
      <IconButton onClick={onClick}>
        <Icon />
      </IconButton>
      <NotificationsList open={open} onClose={close} />
    </>
  )
}

export default NotificationsCenter
