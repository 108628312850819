import { useTranslation } from 'react-i18next'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { useHistory } from 'react-router-dom'

const DialogFullHdVideoRequestElite = ({ open, onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const camera = useSelectedCamera()

  const acknowledgeRequest = () => {
    onClose()
    history.push(`/camera/${camera.id}/plan`)
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('photo_viewer.request_full_hd_video.request_full_hd_video_elite_plan.title')}
      text={t('photo_viewer.request_full_hd_video.request_full_hd_video_elite_plan.text')}
      confirmText={t('photo_viewer.request_full_hd_video.request_full_hd_video_elite_plan.confirm')}
      onConfirm={acknowledgeRequest}
      onCancel={onClose}
    />
  )
}

export default DialogFullHdVideoRequestElite
