import { useHistory } from 'react-router-dom'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import VoskerBackArrowIcon from 'vosker/src/photos-module/photo/ui/icons/VoskerBackArrowIcon'
import VoskerButtonWrapper from 'vosker/src/photos-module/photo/ui/actions-bar/VoskerButtonWrapper'
import { useTheme } from '@material-ui/core'

const BackButton = () => {
  const theme = useTheme()
  const history = useHistory()
  const camera = useSelectedCamera()

  const returnToGallery = () => {
    if (history.location.state?.fromPage === 'photoGallery') {
      history.push('/')
    } else {
      history.push(`/camera/${camera.id}`)
    }
  }

  return camera
    ? (
      <VoskerButtonWrapper onClick={returnToGallery} data-testid="back-button">
        <VoskerBackArrowIcon color={theme.palette.basic?.lightGrey} />
      </VoskerButtonWrapper>
      )
    : null
}

export default BackButton
