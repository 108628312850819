import * as Yup from 'yup'

const V300_ULTIMATE = 'V300-ULTIMATE'
const VKX = 'VKX'
const VKX_STREAM = 'VKX-STREAM'

const getInitialValues = () => ({
  deviceModel: '',
  activationCode: '',
  deviceName: '',
  purpose: '',
})

const rules = {}
const getValidationSchema = t => {
  rules.deviceModel = Yup.string().required()
  rules.activationCode = Yup.string()
    .required()
    .test('activation-code-validation', t('app:active_device.form_layout.errors.activation_code'), (value) => {
      const activationCodePattern = /^[A-Z2-7]{11,14}$/
      const formattedValue = value?.replace(/-/g, '')
      const isValidMaxLength = formattedValue?.length <= 14
      const isValidMinLength = formattedValue?.length >= 11
      const isActivationCodeValid = activationCodePattern.test(formattedValue)
      const isValidFormat = value?.charAt(4) === '-' && value?.charAt(8) === '-'
      const acceptAlphanumeric = !/[^a-zA-Z0-9]/g.test(formattedValue)

      const firstCriteria = isValidMinLength && isValidFormat && acceptAlphanumeric
      const secondCriteria = isValidMaxLength && isActivationCodeValid && acceptAlphanumeric
      return firstCriteria || secondCriteria
    })
  rules.deviceName = Yup.string().required()
  rules.purpose = Yup.string().required()

  return Yup.object().shape(rules)
}

const allFieldAreChanged = formik =>
  formik.values.deviceModel !== formik.initialValues.deviceModel &&
  formik.values.activationCode !== formik.initialValues.activationCode &&
  formik.values.deviceName !== formik.initialValues.deviceName &&
  formik.values.purpose !== formik.initialValues.purpose

const generatorDefaultCameraName = (values, cameras) => {
  const cameraCount = cameras.filter(camera => camera.status.model === values.deviceModel).length + 1
  return `${values.deviceModel}-` + cameraCount
}

const isCameraModelSelected = formik => formik.dirty && formik.values.deviceModel !== ''

const isCameraNameEdited = (formik, cameras, previousValues) =>
  formik.dirty &&
  formik.values.deviceName !== '' &&
  formik.values.deviceName !== generatorDefaultCameraName(previousValues, cameras)

const shouldDisplayVerifyCardMessage = formik =>
  formik.values.deviceModel !== V300_ULTIMATE &&
  formik.values.deviceModel !== VKX &&
  formik.values.deviceModel !== VKX_STREAM

const shouldDisplayQuickGuide = formik => formik.values.deviceModel === VKX

const formatsActivationCode = formik => {
  const value = formik.values.activationCode
  const formattedValue = value.replace(/\W/g, '').toUpperCase()

  let joinedValue = ''
  if (formattedValue.length <= 4) {
    joinedValue = formattedValue
  } else if (formattedValue.length <= 7) {
    joinedValue = formattedValue.slice(0, 4) + '-' + formattedValue.slice(4)
  } else if (formattedValue.length <= 11) {
    joinedValue = formattedValue.slice(0, 4) + '-' + formattedValue.slice(4, 7) + '-' + formattedValue.slice(7)
  } else {
    joinedValue = formattedValue.slice(0, 14)
  }

  joinedValue = joinedValue.slice(0, 14)
  formik.setFieldValue('activationCode', joinedValue)
}

const cameraActivationHelper = {
  getInitialValues,
  getValidationSchema,
  allFieldAreChanged,
  isCameraModelSelected,
  generatorDefaultCameraName,
  isCameraNameEdited,
  shouldDisplayQuickGuide,
  shouldDisplayVerifyCardMessage,
  formatsActivationCode,
}
export default cameraActivationHelper
