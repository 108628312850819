import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const CameraActivationEmptyIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="CameraActivationEmptyIcon" width="338" height="120" viewBox="0 0 338 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        clipPath="url(#clip0_14847_240975)"
      >
        <path
          d="M50.3334 46.6666L52.6765 49.0097L41.7004 59.9857L52.6907 70.976L50.3334 73.3333L38.4816 61.4814C38.0887 61.0886 37.868 60.5556 37.868 60C37.8679 59.4443 38.0887 58.9114 38.4816 58.5185L50.3334 46.6666Z"
          fill={`${theme.palette.border?.primary} !important`}
        />
      </g>
      <path
        d="M122.24 10.3955C122.878 10.1323 123.562 9.99789 124.253 10C125.646 10 126.981 10.5534 127.966 11.5383C128.951 12.5233 129.505 13.8592 129.505 15.2522V78.1142H128.971C128.971 78.1142 119.001 78.1143 119.001 68.1433V15.2522C118.998 14.5618 119.133 13.8779 119.396 13.2397C119.659 12.6016 120.046 12.0217 120.534 11.5336C121.022 11.0455 121.602 10.6587 122.24 10.3955Z"
        fill={`${theme.palette.border?.primary} !important`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.937 30.77L213.133 36.4641C214.879 37.1812 216.37 38.4039 217.416 39.975C218.461 41.5462 219.013 43.3941 219.001 45.2813V79.9821C219.001 81.8729 218.434 83.7205 217.374 85.2864C216.314 86.8523 214.81 88.0649 213.054 88.7677L198.858 94.4775C197.739 94.9218 196.546 95.1519 195.341 95.1558H163.338C162.133 95.1534 160.939 94.9232 159.82 94.4775L145.624 88.7677C143.869 88.0649 142.364 86.8523 141.304 85.2864C140.244 83.7205 139.678 81.8729 139.678 79.9821V45.2813C139.675 43.3795 140.246 41.5211 141.315 39.9484C142.384 38.3756 143.902 37.1614 145.672 36.4641L159.867 30.77C160.987 30.3243 162.18 30.0942 163.385 30.0918H195.42C196.624 30.0957 197.817 30.3257 198.937 30.77ZM201.702 54.7616H186.417V70.046H201.702V54.7616Z"
        fill={`${theme.palette.border?.primary} !important`}
      />
      <g
        clipPath="url(#clip1_14847_240975)"
      >
        <path
          d="M287.667 73.3334L285.324 70.9904L296.3 60.0143L285.31 49.024L287.667 46.6667L299.519 58.5186C299.912 58.9114 300.133 59.4444 300.133 60C300.133 60.5557 299.912 61.0886 299.519 61.4815L287.667 73.3334Z"
          fill={`${theme.palette.border?.primary} !important`}
        />
      </g>

      <defs>
        <clipPath id="clip0_14847_240975">
          <rect width="32" height="32" fill={theme.palette.background.paper + ' !important'} transform="translate(29 44)" />
        </clipPath>
        <clipPath id="clip1_14847_240975">
          <rect width="32" height="32" fill={theme.palette.background.paper + ' !important'} transform="translate(277 44)" />
        </clipPath>
      </defs>

    </SvgIcon>
  )
}

export default CameraActivationEmptyIcon
