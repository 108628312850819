import CameraStatusBatteryTile from 'camera-module/camera/ui/camera-status-page/CameraStatusBatteryTile'
import { getBatteryIcon } from 'camera-module/camera/core/getBatteryIcon'
import { useTranslation } from 'react-i18next'

const MainBatteryTile = ({ value, label, activePowerSource }) => {
  const val = value.percentage
  const { t } = useTranslation()
  const batteryValue = activePowerSource ? `${val}% - ${t('camera.in_use')}` : `${val}%`
  const Battery = getBatteryIcon(val)

  return <CameraStatusBatteryTile label={label} icon={<Battery />} value={val} batteryValue={batteryValue} useSVGIcon />
}

export default MainBatteryTile
