import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'

import { Box, Container } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import makeStyles from '@material-ui/core/styles/makeStyles'

import webview from 'shared-module/webview/webview'
import Profile from 'user-module/user/ui/profile/Profile'
import ManagementPanel from 'user-module/account/management/ManagementPanel'

import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import CTabs from 'storybook-component-module/src/components/tabs/CTabs'

import AccountIcon from 'vosker/src/assets/icons/AccountIcon'
import { getAccountDashboardTabs, getCurrentTabPath } from './VoskerAccountHelpers'
import PaymentTab from 'vosker/src/user-module/payment-information/ui/payment-information/PaymentTab'

import { useECommerceFlag } from 'vosker/src/launchDarkly-module/useEcommerceFlag'

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.background.default,
  },
  container: {
    padding: '2rem 0rem',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 4rem',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '2rem 0rem',
    },
  },
  accountBox: {
    marginTop: webview.isWebview() ? 'calc(0px - 30px)' : theme.spacing(12),
    position: webview.isWebview() ? 'relative' : 'static',
    paddingTop: webview.isWebview() ? theme.spacing(0) : 0,
  },
}))

const Account = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { pathname } = useLocation()
  const eCommerceLdEnabled = useECommerceFlag()

  const tabs = getAccountDashboardTabs(eCommerceLdEnabled)
  const tabIndex = getCurrentTabPath(tabs, pathname)
  const mobileScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const filteredTabs = tabs.filter((tab, index) => {
    if (index === 1 && eCommerceLdEnabled) {
      return false
    }
    return !tab.disabled
  })

  const getContainerWidth = () => {
    switch (pathname) {
      case tabs[0].path:
        return tabs[0].maxWidth
      case tabs[1].path:
        return eCommerceLdEnabled ? null : tabs[1].maxWidth
      case tabs[2].path:
        return tabs[2].maxWidth
      default:
        return 'lg'
    }
  }

  const routeSelectedTable = () => {
    if (pathname === tabs[0].path) {
      return <Profile />
    }
    if (pathname === tabs[1].path && !tabs[1].disabled) {
      return eCommerceLdEnabled ? null : <PaymentTab />
    }
    if (pathname === tabs[2].path && !tabs[2].disabled) {
      return <ManagementPanel />
    }
    return webview.isWebview() ? null : <Redirect to="/account/profile" />
  }

  return (
    <>
      { !webview.isWebview() && (
        <Box sx={{ marginTop: { xs: '103px', sm: '128px' } }} data-testid="account">
          <CAppBar
            variant="h1"
            Icon={AccountIcon}
            className={classes.appbar}
            title={eCommerceLdEnabled ? t('app:menu.account') : t('app:menu.profile')}
            data-testid="apptitle"
            showDivider
          />
          <Container maxWidth={getContainerWidth()} className={classes.container}>
            <CTabs
              tabs={filteredTabs}
              tabIndex={tabIndex}
              variant={mobileScreen ? 'fullWidth' : 'standard'}
              data-testid="ctabs"
            />
            { routeSelectedTable() }
          </Container>
        </Box>
      ) }
      { webview.isWebview() && (
        <Box className={classes.accountBox} data-testid="account">
          <Box sx={{ padding: '0px 24px' }}>
            <Container maxWidth={getContainerWidth()} className={classes.container}>
              { routeSelectedTable() || <Profile /> }
            </Container>
          </Box>
        </Box>
      ) }
    </>
  )
}

export default Account
