import photoState from 'photo-module/photo/core/photoState'
import downloadStatus from 'photo-module/download/downloadStatus'
import messageActions from 'shared-module/message/messageActions'
import cameraActions from 'vosker/src/camera-module/camera/core/cameraActions'
import { updatePhotoInList } from 'photo-module/photos/core/photosActions'
import videoDownloadApi from 'video-module/video-clip/api/videoDownloadApi'

const getVideo = photo => photo.urls.streamVideo || photo.urls.hdVideo || photo.urls.sdVideo
const isAVideo = photo => !!photo?.urls.hdVideo || !!photo?.urls.sdVideo
const isHd = photo => photo.hdVideoStatus === downloadStatus.available
const isAStream = photo => !!photo?.urls.streamVideo && !!photo?.streamVideoDuration
const hasPreview = photo => !!photo?.urls.preview

const requestHdVideo = (cameraId, photoId) => dispatch => videoDownloadApi
  .requestHdVideo(cameraId, photoId)
  .then(() => {
    dispatch(updatePhotoInList(photoId, { hdVideoStatus: downloadStatus.pending }))
    dispatch(messageActions.showWarning('photo_viewer.request_full_hd_video.success'))
  })
  .catch(() => dispatch(messageActions.showError()))
  .finally(() => dispatch(cameraActions.update(cameraId)))

const clearHdVideo = (cameraId, photoId) => dispatch => videoDownloadApi
  .clearHdVideo(cameraId, photoId)
  .then(() => dispatch(photoState.getPhoto(cameraId, photoId)))
  .then((photo) => dispatch(updatePhotoInList(photo.id, photo)))
  .finally(() => dispatch(cameraActions.update(cameraId)))

const videoState = {
  getVideoUrl: getVideo,
  isAVideo: isAVideo,
  isAStream: isAStream,
  isHd: isHd,
  requestHdVideo: requestHdVideo,
  clearHdVideo: clearHdVideo,
  hasPreview: hasPreview,
}
export default videoState
