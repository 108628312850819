import { FETCH_CAMERA_PURPOSES, SET_CAMERA_PURPOSES } from './cameraPurposesActionTypes'
import purposeApi from '../api/CameraPurposesApi'

const getPurposes = () => dispatch => {
  dispatch({ type: FETCH_CAMERA_PURPOSES })
  return purposeApi.getPurposes()
    .then(purposes => dispatch({ type: SET_CAMERA_PURPOSES, purposes: purposes }))
    .catch(() => dispatch({ type: SET_CAMERA_PURPOSES, purposes: [] }))
}

const cameraPurposesActions = { getPurposes }
export default cameraPurposesActions
