import FormControl from '@material-ui/core/FormControl'

import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

const FormButton = ({ children }) => {
  const form = useFormData()

  return (
    <FormControl margin="dense">
      <VoskerPrimaryButton id="btn_login" color="primary" variety="primary" type="submit" disabled={form.formState.isSubmitting}>
        { children }
      </VoskerPrimaryButton>
    </FormControl>
  )
}

export default FormButton
